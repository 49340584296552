export interface ThumbnailList {
  title: string;
  items: Thumbnail[];
}

export enum ThumbnailType {
  Lesson = 'lesson',
  Video = 'video',
  Files = 'files',
  File = 'file',
  LessonSlideScroll = 'lesson_slide_scroll',
  Contents = 'contents',
  Items = 'items'
}

export enum FavouriteItemType {
  ContentGroup = 'content_group',
  FileGroup = 'file_group',
  Content = 'content',
  Competence = 'competence'
}

export enum CategoryFilter {
  Video = 'video',
  Files = 'files',
  ELearning = 'eLearning'
}

export enum TagFilter {
  Cyclical = 'cyclical',
  Required = 'required'
}

export interface Competence {
  id: number;
  name: string;
  type: {
    id: number;
    name: string;
  };
  skills: [
    {
      id: number;
      name: string;
    }
  ];
  skillsMap: [];
}

export interface Tag {
  id: number;
  name: string;
  type: string;
  active: boolean;
}

export enum LessonStatus {
  Finished = 'finished',
  InProgress = 'in_progress',
  NotStarted = 'not_started',
  Failed = 'failed',
  ValidateTo = 'validate_to'
}

export enum Award {
  Gold = 'gold',
  Silver = 'silver',
  Bronze = 'bronze',
  Completed = 'completed'
}

export interface Thumbnail {
    id: number;
    type: ThumbnailType;
    category: string;
    name: string;
    description: string;
    rank: number;
    recommended: boolean;
    required: boolean;
    activatedAt: number | null;
    competences: Competence[];
    tags: Tag[];
    fastForward: boolean;
    award: Award | null;
    completed: boolean;
    validity: null;
    hasAccess: boolean;
    image?: string;
    items?: Thumbnail[];
    favorite?: boolean | null;
    status?: string;
    statusKey?: LessonStatus;
    cyclical: boolean;
    progress?: number;
    minutes?: number | null;
    url?: string;
}

export type KnowledgeBaseData = {
  data: Data;
};

export interface Data {
  elements: ThumbnailList[];
}
