import { AxiosResponse } from 'axios';

import { handleApiError, phpApi } from '../../../api';
import { ApiResolve } from '../../../types/api';
import { EnrollEvent, EventDetails, FormData, UserDetails } from '../eventForm.types';

export const getEventDetails: (id: number) => Promise<EventDetails> = (id: number): Promise<EventDetails> => new Promise(
  (resolve: ApiResolve<EventDetails>): Promise<void | never[]> => (
    phpApi.get(`/api/events/${id}`)
      .then((response: AxiosResponse<EventDetails>): void => resolve(response.data))
      .catch(handleApiError)
  )
);

export const getUserInfo: () => Promise<UserDetails> = (): Promise<UserDetails> => new Promise(
  (resolve: ApiResolve<UserDetails>): Promise<void | never[]> => (
    phpApi.get('/api/user/info')
      .then((response: AxiosResponse<UserDetails>): void => resolve(response.data))
      .catch(handleApiError)
  )
);

export const enrollFormEvent: (eventId: number, data: FormData) => Promise<EnrollEvent>
= (eventId: number, data: FormData): Promise<EnrollEvent> => new Promise(
  (resolve: ApiResolve<EnrollEvent>): Promise<void | never[]> => (
    phpApi.post(`/api/events/${eventId}/enroll`, data)
      .then((response: AxiosResponse<EnrollEvent>): void => resolve(response.data))
      .catch(handleApiError)
  )
);
