import { t } from 'i18next';
import * as yup from 'yup';

import { emailRegexp, polishPhone } from '../../../../regexps';

import { Answers } from './form.types';

export const schema: yup.ObjectSchema<Answers> = yup.object({
  name: yup
    .string()
    .required(t('competence.app.eventForm.form.required'))
    .min(2, t('competence.app.eventForm.form.invalidName'))
    .max(64, t('competence.app.eventForm.form.invalidName')),
  surname: yup
    .string()
    .required(t('competence.app.eventForm.form.required'))
    .min(2, t('competence.app.eventForm.form.invalidSurname'))
    .max(64, t('competence.app.eventForm.form.invalidSurname')),
  email: yup
    .string()
    .required(t('competence.app.eventForm.form.required'))
    .matches(
      emailRegexp,
      t('competence.app.eventForm.form.invalidEmail'),
    )
    .max(128, t('competence.app.eventForm.form.invalidEmail')),
  userPhone: yup
    .string()
    .required(t('competence.app.eventForm.form.required'))
    .matches(
      polishPhone,
      t('competence.app.eventForm.form.invalidPhone'),
    ),
}).required();
