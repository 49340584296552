import { put } from 'redux-saga/effects';

import { EnrollEvent, EventDetails, UserDetails } from '../eventForm.types';
import * as eventFormApi from '../services/api';

import { EventFormActions } from './eventForm.actions';
import { FetchEventDetails, SubmitForm } from './eventForm.types';

export function* fetchEventDetails({ id }: FetchEventDetails): Generator {
  const data: EventDetails = (yield eventFormApi.getEventDetails(id)) as EventDetails;
  yield put(EventFormActions.setEventDetails(data));
}

export function* fetchUserInfo(): Generator {
  const data: UserDetails = (yield eventFormApi.getUserInfo()) as UserDetails;
  yield put(EventFormActions.setUserData(data));
}

export function* enrollEvent({ eventId, formData, onFinish }: SubmitForm): Generator {
  const data: EnrollEvent = (yield eventFormApi.enrollFormEvent(eventId, formData)) as EnrollEvent;

  if (data.success) {
    onFinish();
  }
}
