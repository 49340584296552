import {
  Breakpoint,
  Button,
  ButtonSize,
  ButtonTheme,
  ButtonType,
  FontWeight,
  Input,
  SwitchFrame,
  TextButton,
  TextButtonTheme,
  Typography,
  TypographyVariant,
  UseState,
} from '@elearning-platform/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { Controller, useForm, UseFormReturn } from 'react-hook-form';
import { TransProps, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { NavigateFunction, Params, useNavigate, useParams } from 'react-router';
import { AnyAction, Dispatch } from 'redux';

import { EntrySurvey, EventDetails, FormData, UserDetails } from '../../eventForm.types';
import BedIcon from '../../images/bedIcon.svg';
import ParkingIcon from '../../images/parkingIcon.svg';
import PizzaIcon from '../../images/pizzaIcon.svg';
import Shape from '../../images/shape.svg';
import { EventFormActions, selectEventDetails, selectUserInfo } from '../../redux';

import { schema } from './form.schema';
import {
  AddonsSection,
  ButtonsContainer,
  Container,
  FormContainer,
  FormWrapper,
  MobileShape,
  ShapeContainer,
  StyledInput,
  StyledShape,
  SupervisorSection,
  UserDataSection,
  Wrapper,
} from './form.styled';
import { Answers, ControllerRender } from './form.types';

export const Form: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const userDetails: UserDetails | null = useSelector(selectUserInfo) as UserDetails | null;
  const eventData: EventDetails | null = useSelector(selectEventDetails) as EventDetails | null;
  const dispatch: Dispatch<AnyAction> = useDispatch();
  const navigate: NavigateFunction = useNavigate();
  const { id }: Params = useParams();
  const [addons, setAddons]: UseState<EntrySurvey> = useState<EntrySurvey>({
    withAccommodation: false,
    withParking: false,
    withVege: false,
  });
  const isSmallDesktop: boolean = useMediaQuery({
    query: Breakpoint.SmallDesktop,
  });
  const isTablet: boolean = useMediaQuery({
    query: Breakpoint.Tablet,
  });

  const { control, handleSubmit, formState: { errors } }: UseFormReturn<Answers> = useForm({
    defaultValues: {
      name: '',
      surname: '',
      email: '',
      userPhone: userDetails?.userPhone || '',
    },
    resolver: yupResolver(schema),
  });

  useEffect((): void => {
    dispatch(EventFormActions.getUserData());
  }, [dispatch]);

  const onFinish: () => void = (): void => {
    navigate(`/szkolenia/${id}`);
  };

  const onSubmit: (data: Answers) => void = (data: Answers): void => {
    const formData: FormData = {
      supervisor: {
        name: data.name,
        surname: data.surname,
        email: data.email,
      },
      user: {
        email: userDetails?.email || '',
        phone: data.userPhone,
        accommodation: addons.withAccommodation,
        parking: addons.withParking,
        vege: addons.withVege,
      },
    };

    dispatch(EventFormActions.sendEventForm(id, formData, onFinish));
  };

  const toggleHandler: (key: keyof EntrySurvey) => void = (key: keyof EntrySurvey): void => {
    setAddons((prev: EntrySurvey): EntrySurvey => ({ ...prev, [key]: !prev[key] }));
  };

  return (
    <Container>
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <FormWrapper>
          <SupervisorSection>
            <Typography fontWeight={FontWeight.Bold} variant={TypographyVariant.Subheader}>
              {t('competence.app.eventForm.form.supervisorData')}
            </Typography>
            <Wrapper>
              <Controller
                control={control}
                name='name'
                render={({ field }: ControllerRender<'name'>): JSX.Element => (
                  <StyledInput
                    {...field}
                    description={errors.name?.message}
                    id='name'
                    label={t('competence.app.eventForm.form.name')}
                    withError={!!errors.name}
                  />
                )}
              />
              <Controller
                control={control}
                name='surname'
                render={({ field }: ControllerRender<'surname'>): JSX.Element => (
                  <StyledInput
                    {...field}
                    description={errors.surname?.message}
                    id='surname'
                    label={t('competence.app.eventForm.form.surname')}
                    withError={!!errors.surname}
                  />
                )}
              />
            </Wrapper>
            <Controller
              control={control}
              name='email'
              render={({ field }: ControllerRender<'email'>): JSX.Element => (
                <Input
                  {...field}
                  description={errors.email?.message}
                  id='email'
                  label={t('competence.app.eventForm.form.email')}
                  withError={!!errors.email}
                />
              )}
            />
          </SupervisorSection>
          <UserDataSection>
            <Typography fontWeight={FontWeight.Bold} variant={TypographyVariant.Subheader}>
              {t('competence.app.eventForm.form.userData')}
            </Typography>
            <Wrapper>
              <StyledInput disabled id='userEmail' label={t('competence.app.eventForm.form.email')} value={userDetails?.email || ''} />
              <Controller
                control={control}
                name='userPhone'
                render={({ field }: ControllerRender<'userPhone'>): JSX.Element => (
                  <StyledInput
                    {...field}
                    description={errors.userPhone?.message}
                    id='userPhone'
                    label={t('competence.app.eventForm.form.phone')}
                    leftElement='+48'
                    leftElementWidth={36}
                    withError={!!errors.userPhone}
                  />
                )}
              />
            </Wrapper>
          </UserDataSection>
          <AddonsSection>
            <Typography fontWeight={FontWeight.Bold} variant={TypographyVariant.Subheader}>
              {t('competence.app.eventForm.form.addons')}
            </Typography>
            {!!eventData?.entrySurvey?.withAccommodation && (
              <SwitchFrame
                id='reserveAccomodation'
                image={BedIcon}
                onToggle={(): void => toggleHandler('withAccommodation')}
                text={t('competence.app.eventForm.form.reserveAccomodation')}
                toggled={addons.withAccommodation}
              />
            )}
            {!!eventData?.entrySurvey?.withParking && (
              <SwitchFrame
                id='reserveParking'
                image={ParkingIcon}
                onToggle={(): void => toggleHandler('withParking')}
                text={t('competence.app.eventForm.form.reserveParking')}
                toggled={addons.withParking}
              />
            )}
            {!!eventData?.entrySurvey?.withVege && (
              <SwitchFrame
                id='reserveVege'
                image={PizzaIcon}
                onToggle={(): void => toggleHandler('withVege')}
                text={t('competence.app.eventForm.form.veganDiet')}
                toggled={addons.withVege}
              />
            )}
          </AddonsSection>
        </FormWrapper>
        <ButtonsContainer>
          <Button
            fullWidth={!isSmallDesktop}
            label={t('competence.app.eventForm.form.save')}
            size={ButtonSize.Large}
            type={ButtonType.Submit}
            variant={ButtonTheme.PrimaryBrand}
          />
          {/* TODO: add correct redirection when previous view will be available */}
          <TextButton
            label={t('competence.app.eventForm.form.cancel')}
            onClick={(): void => navigate('/')}
            variant={TextButtonTheme.TertiaryGrey}
          />
        </ButtonsContainer>
        {!isTablet && <MobileShape />}
      </FormContainer>
      {!!isSmallDesktop && (
        <ShapeContainer>
          <StyledShape src={Shape} />
        </ShapeContainer>
      )}
    </Container>
  );
};
