import React, { Component } from "react";

import * as Interactions from "../../interactions";
import { AppConfig } from "../../appConfig";

const InteractionComponents = new Map();

InteractionComponents.set("interactionError", Interactions.InteractionError);
InteractionComponents.set("video", Interactions.VideoSection);
InteractionComponents.set("download", Interactions.SimpleDownload);
InteractionComponents.set("table_text_img.js", Interactions.TableTextImage);
InteractionComponents.set("tableTextImg.js", Interactions.TableTextImage);
InteractionComponents.set("changeOnHover.js", Interactions.ChangeOnHover);
InteractionComponents.set("download.js", Interactions.Download);
InteractionComponents.set("show_text_img.js", Interactions.ShowTextImage);
InteractionComponents.set("SimpleClickEnd.js", Interactions.SimpleClickEnd);
InteractionComponents.set("videos.js", Interactions.Videos);
InteractionComponents.set("calculator.js", Interactions.Calculator);
InteractionComponents.set("select-area.js", Interactions.SelectArea);
InteractionComponents.set("path-choice.js", Interactions.PathChoice);
InteractionComponents.set("put-in-order.js", Interactions.PutInOrder);
InteractionComponents.set("dragAndDrop.js", Interactions.DragAndDrop);
InteractionComponents.set("checkBoxes.js", Interactions.CheckBoxes);
InteractionComponents.set("trueFalseAnswer.js", Interactions.TrueFalseAnswer);
InteractionComponents.set("timerSlides.js", Interactions.TimerSlides);
InteractionComponents.set("onboardingInteractions.js", Interactions.OnboardingInteractions);
InteractionComponents.set("opponentTest.js", Interactions.OpponentTest);
InteractionComponents.set("codeOfConductInteractions.js", Interactions.SequentialQuizInteractions);
InteractionComponents.set("sequentialQuizInteractions.js", Interactions.SequentialQuizInteractions);
InteractionComponents.set("scrollContent.js", Interactions.ScrollContent);
InteractionComponents.set("quizQuestions.js", Interactions.QuizQuestions);
InteractionComponents.set("dragAndDropOrder.js", Interactions.DragAndDropOrder);

class Interaction extends Component {
  render() {
    let InteractionComponentsKey;
    switch (this.props.type) {
    case "video":
      InteractionComponentsKey = "video";
      break;
    case "download":
      InteractionComponentsKey = "download";
      break;
    case "interaction":
    case "task":
      InteractionComponentsKey = this.props.params.find(param => param.name === "url").value;
      break;
    default:
      InteractionComponentsKey = "interactionError";
    }

    const InteractionComponent = InteractionComponents.has(InteractionComponentsKey)
      ? InteractionComponents.get(InteractionComponentsKey)
      : InteractionComponents.get("interactionError");

    const {
      id,
      callComplete,
      title,
      onListClick,
      passed,
      currentSlideIndex,
      slidesOverall,
      subtitle,
      tags,
    } = this.props;
    const params = this.props.params.reduce((obj, val) => ({ ...obj, [val.name]: val.value }), {});
    const childProps = { ...params, ...this.props.ui };

    //Important: source for materials
    //-------------------------------------------------------------------------------------------------------------
    const platformMaterialsSource = AppConfig.content;
    childProps["baseUrl"] =
                (this.props.baseUrl.substr(0,4) === "http" || this.props.baseUrl.substr(0,5) === "https")
                  ? this.props.baseUrl
                  : platformMaterialsSource + this.props.baseUrl;
    //-------------------------------------------------------------------------------------------------------------

    if (InteractionComponentsKey === "download") {
      childProps["data"] = this.props.data;
    }

    return (
      <div style={{ position: "relative", width: "100%", minHeight: "100%" }}>
        <InteractionComponent
          {...childProps}
          id={id}
          callComplete={() => {
            callComplete();
          }}
          onListClick={onListClick}
          title={title}
          timelineDisabled={!passed}
          currentSlideIndex={currentSlideIndex}
          slidesOverall={slidesOverall}
          subtitle={subtitle}
          isInteraction
          tags={tags}
        />
      </div>
    );
  }
}

export default Interaction;
