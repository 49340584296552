import { IconName } from "@elearning-platform/ui";

import { ReactComponent as ModuleIcon } from "../../images/trainings.svg";

export const Config = {
  moduleName: "trainings",
  dependencies: [],
  label: "app.mainNavigation.courses",
  Icon: ModuleIcon,
  urls: ["/szkolenia"],
  routeName: "api_events_default_request",
  routes: {},
  options: {},
  theme: {},
  modernIconName: IconName.PageTrainings,
};
