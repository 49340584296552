import { put, takeLatest } from 'redux-saga/effects';

import { KnowledgeBaseData } from '../knowledgeBaseV2.types';
import * as knowledgeBaseApi from '../services/api';

import { KnowledgeBaseActions, KnowledgeBaseTypes } from './knowledgeBaseV2.reducer';

function* getThumbnailListsAndTags(): Generator {
  const { data }: KnowledgeBaseData = (yield knowledgeBaseApi.getThumbnailListsAndTags()) as KnowledgeBaseData;

  if (data) {
    yield put(KnowledgeBaseActions.getThumbnailListsSuccess(data.elements));
  }
}

export function* watchKnowledgeBase(): Generator {
  yield takeLatest(KnowledgeBaseTypes.GET_THUMBNAIL_LISTS_AND_TAGS, getThumbnailListsAndTags);
}
