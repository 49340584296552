import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { MultiFilesDrawer } from "../components/thumbnail/components/multiFilesDrawer/multiFilesDrawer.component";
import { ItemType } from "../components/thumbnail/thumbnail.constants";
import { colors, breakpoints } from "../UIGlobals";
import { AppConfig } from "../appConfig";
import { Transitions } from "../theme";
import PlaceholderImg from '../images/defaultPlaceholder.jpg';
import { isFullUrl } from "../utils";

import {
  Badge,
  Icon,
  ThumbnailWarning,
} from "./";

const Wrapper = styled.div`
  max-width: ${props => (props.wide ? "" : "285px")};
  width: ${props => (props.containerWidth ? props.containerWidth : (props.wide ? "calc(33% - 15px)" : "calc(25% - 15px)"))};
  position: relative;
  cursor: ${props => (props.$disableCursor ? "auto" : "pointer")};
  flex-shrink: ${props => (props.$flexShrink ? props.$flexShrink : "0")};
  scroll-snap-align: start;
  @media (${breakpoints().minL}) {
    &:hover{
      .opacityContainer{
        img {
          transform: scale(1.2);
          opacity: 0.6;
        }
      }
    }
  }
  @media (${breakpoints().maxL}) {
    width: ${props => (props.containerWidth ? props.containerWidth : "27.5%")};
  }
  @media (${breakpoints().maxM}) {
    width: ${props => (props.containerWidth ? props.containerWidth : "calc(42.5% - 20px)")};
  }
  @media (${breakpoints().maxS}) {
    width: ${props => (props.containerWidth ? props.containerWidth : "calc(75% - 20px)")};
  }
  & + &{
    margin-left:20px;
  }
`;

const ThumbnailImage = styled.a`
  width: 100%;
  text-align: center;
  display: block;
  background: no-repeat center center;
  background-color: ${props => (props.backgroundColor ? props.backgroundColor : "inherit")};
  background-size: contain;
  background-image: url(${props => props.background});
  transition: ${Transitions.custom};
  img {
    max-height: 180px;
    max-width: 285px;
    ${props => (props.$controlledHeight && `
      height: 135px;
      object-fit: cover;
    `)};
    max-width: 100%;
    width: ${props => (props.width ? props.width : "auto")};
    left: 0;
    top: 0;
    transition: all .8s ease-in-out;
    opacity: 1;
    transform: scale(1.02);

    @media (${breakpoints().maxM}) {
      ${props => (props.$controlledHeight && `
        height: 96px;
      `)};
    }
  }
`;

const ThumbnailContainer = styled.div`
  max-width: 285px;
  position: relative;
`;

const OpacityContainer = styled.div`
  display: block;
  position: relative;
  overflow: hidden;
  background: #000;
`;

const ThumbnailBadges = styled.div`
  position: absolute;
  left: 10px;
  top: ${props => (props.top ? props.top : "10px")};
  height: 33px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  @media (${breakpoints().maxL}) {
    left: ${props => (props.left ? props.left : "10px")};
  }
`;

const ThumbailProgress = styled.div`
  height: 4px;
  width: ${props => (props.progress > 0 && props.progress < 1 ? "100%" : "0%")};
  background: #a8a9ad;
  position: absolute;
  bottom: 0px;
  left: 0px;
  &:after {
    display: block;
    content: "";
    width: ${props => props.progress * 100 + "%"};
    height: 4px;
    position: absolute;
    left: 0px;
    top: 0px;
    background: ${props => props.$brandColor};
  }
`;

const Title = styled.h1`
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.25;
  text-align: left;
  color: ${colors().deepGray};
  margin-top: 8px;
`;

const Subtitle = styled.h2`
  font-size: 1rem;
  font-weight: 600;
  line-height: 2;
  color: ${colors().gray3};
  margin-top: 10px;
  text-transform: uppercase;
`;

const ThumbnailBatchOverlay = styled.div`
  width: 100px;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  right: 0px;
  top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ThumbnailBatchCount = styled.span`
  font-size: 2rem;
  line-height: 1;
  text-align: center;
  color: ${colors().white};
  display: block;
  margin-bottom: 15px;
`;

const MultiIcon = ({ type }) => (
  <div>
    <Icon icon={type === "video" ? "playlist_video" : "playlist_files"} color={colors().white} size={20} />
  </div>
);

const Thumbnail = ({
  title,
  category,
  date,
  uri,
  localImage,
  badges = [],
  items,
  itemsType = "video",
  itemsDetails,
  action = () => {},
  progress = 0,
  wide = false,
  children,
  background = false,
  width,
  controlledHeight,
  containerWidth,
  size,
  flexShrink,
  disableCursor,
  required,
  hideTitle,
  className,
  isSearchView,
}) => {
  const shouldAddAction = children ? children.find(child => child !== false) : false;
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [currentImg, setCurrentImg] = useState(() => isFullUrl(uri)
    ? uri
    : `${AppConfig.content}${uri}`
  );

  useEffect(() => {
    if (!drawerVisible && isSearchView) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [drawerVisible, isSearchView]);

  const handleAction = () => {
    if (!itemsDetails?.length) {
      action();
    } else {
      setDrawerVisible(true);
    }
  };

  const handleCloseDrawer = () => setDrawerVisible(false);

  return (
    <>
      <Wrapper
        onClick={() => {if(!shouldAddAction) handleAction()}} wide={wide}
        containerWidth={containerWidth}
        $flexShrink={flexShrink}
        $disableCursor={disableCursor}
        className={className}
      >
        <ThumbnailImage backgroundColor={background} width={width} $controlledHeight={controlledHeight}>
          <ThumbnailContainer>
            <OpacityContainer className="opacityContainer" >
              <img onError={() => setCurrentImg(PlaceholderImg)} src={localImage || currentImg} alt="" />
            </OpacityContainer>
            {items > 0 && (
              <ThumbnailBatchOverlay>
                <ThumbnailBatchCount>{items}</ThumbnailBatchCount>
                <MultiIcon type={itemsType} />
              </ThumbnailBatchOverlay>
            )}

            <ThumbnailBadges>
              {badges.map(name => <Badge name={name} key={name} size={size ? size : 32} />)}
            </ThumbnailBadges>

            <ThumbailProgress progress={progress} $brandColor={colors().brandBasic} />
            {required && <ThumbnailWarning />}
          </ThumbnailContainer>
        </ThumbnailImage>

        {children}
        {category && !date && <Subtitle>{category}</Subtitle>}
        {category &&
          date && (
          <Subtitle>
            {category} | {date}
          </Subtitle>
        )}
        {!hideTitle && !!title && <Title>{title}</Title>}
      </Wrapper>
      {!!itemsDetails?.length && (
        <MultiFilesDrawer
          open={drawerVisible && !!itemsDetails}
          items={itemsDetails}
          title={title}
          onClose={handleCloseDrawer}
          type={itemsType === ItemType.Files ? itemsType : ItemType.Contents}
        />
      )}
    </>
  );
};

export { Thumbnail };
