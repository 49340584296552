export const QuestionType = {
  SingleChoice: "singleChoice",
  MultipleChoice: "multipleChoice",
  TrueFalse: "trueFalseAnswer",
  Error: "error",
};

export const TaskStatus = {
  Initialized: "initialized",
  Done: "done",
};
