import { AppConfig } from "../appConfig";
import { Path } from "../enums";
import i18n from "../translations/i18n";

export const OnboardingMode = {
  Default: "onboarding",
  LeasinGo: "onboarding-leasingo",
  OnboardingAll: "onboarding-all",
  HR: "onboarding-hr",
  OnboardingVWFS: "onboarding-vwfs",
};

export const StorageName = {
  OnboardingBoardView: "onboarding-board-view",
};

export const FooterLinks = [
  { label: i18n.t("competence.app.footer.informationDuty"), url: Path.InformationDuty },
  { label: i18n.t("competence.app.footer.privacyPolicy"), url: Path.PrivacyPolicy },
  { label: i18n.t("competence.app.footer.regulations"), url: Path.Regulations },
  { label: i18n.t("competence.app.footer.trainingRegulations"), url: Path.TrainingRegulations },
  { label: i18n.t("competence.app.footer.help"), url: `mailto:${AppConfig.platformContactEmail}` },
];
