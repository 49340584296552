import { ReactComponent as ModuleIcon } from "./images/moduleIcon.svg";

export const Config = {
  moduleName: "scrollLesson",
  dependencies: [
    "@videoPlayer/videoSection",
  ],
  label: "Scroll lesson",
  Icon: ModuleIcon,
  urls: ["/scroll-lesson"],
  routeName: "",
  routes: {},
  options: {},
  theme: {}
};
