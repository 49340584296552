import { IconName } from "@elearning-platform/ui";

import { ReactComponent as ModuleIcon } from "../../images/certificates.svg";

export const Config = {
  moduleName: "certificates",
  dependencies: [],
  label: "certificates.certificates.certificatesHeader",
  Icon: ModuleIcon,
  urls: ["/certyfikaty/*", "/certyfikaty"],
  routeName: "api_certificate_list",
  routes: {
    init: "",
    certificatePath: "/:id",
  },
  options: {},
  theme: {},
  modernIconName: IconName.PageAchievements,
};
