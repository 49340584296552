export enum Path {
  Main = '/',
  ConfirmEmail = '/confirm-email',
  EmailVerification = '/email-verification/:token',
  RemindPassword = '/remind-password',
  Dashboard = '/pulpit',
  KnowledgeBase = '/lekcje',
  FormsAndTests = '/formularze',
  ProfileDetails = '/profil/details',
  InformationDuty = '/klauzula-informacyjna',
  PrivacyPolicy = '/polityka-prywatnosci',
  Regulations = '/regulamin',
  TrainingRegulations = '/regulamin-szkolen',
  EventForm = '/event-form/:id',
  Trainings = '/szkolenia',
  Training = '/szkolenia/:id'
}
