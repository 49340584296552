import { ReactComponent as ModuleIcon } from "../../images/trainings.svg";

export const Config = {
  moduleName: "trainingsV2",
  dependencies: [],
  label: "competence.app.trainingsV2.label",
  Icon: ModuleIcon,
  urls: ["/szkolenia"],
  routeName: "api_events_default_request",
  routes: {},
  options: {},
  theme: {},
  ignoreModuleFromGlobalState: true,
};
