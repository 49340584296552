import { Breakpoint, Button, Label, StyledComponent } from '@elearning-platform/ui';
import { styled } from 'styled-components';

export const Container: StyledComponent = styled.div`
  padding: 48px 16px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
  position: relative;
  width: 100%;

  @media ${Breakpoint.SmallDesktop} {
    padding: 48px 16px;
    max-width: 528px;
    margin-left: auto;
    margin-right: auto;
    /* Max Height = Screen height - TopBar Height and BottomBar height */
    height: calc(100vh - 84px - 80px);
    overflow-y: auto;
  }
`;

export const SelectBoxes: StyledComponent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledButton: StyledComponent<typeof Button> = styled(Button)`
  min-width: 260px;
`;

export const StyledInfoChip: StyledComponent = styled(Label)`
  max-width: fit-content;
  margin: 0 auto;

  @media ${Breakpoint.SmallDesktop} {
    position: absolute;
    bottom: -57px;
  }
`;
