import {
  Breakpoint,
  Card,
  CardData,
  CardsGroup,
  CardVariant,
  Categories,
  Category,
  CheckboxSize,
  CheckboxType,
  CheckboxVariant,
  CollapsibleSidePanel,
  HelpBanner,
  IconName,
  LabelVariant,
  Switch,
  SwitchVariant,
  TypographyVariant,
  UseState,
} from '@elearning-platform/ui';
import React, { Dispatch, useEffect, useMemo, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { AnyAction } from 'redux';

import { AppConfig } from '../../appConfig';
import { downloadAll } from '../../helpers/zipDownload';
import { CommonActions } from '../../redux/common/common.reducer';

import DesktopImage from './images/bg-desktop.jpg';
import MobileImage from './images/bg-mobile.jpg';
import DocTile from './images/docTile.svg';
import JpgTile from './images/jpgTile.svg';
import PdfTile from './images/pdfTile.svg';
import PngTile from './images/pngTile.svg';
import PptTile from './images/pptTile.svg';
import Shape from './images/shape.svg';
import XlsTile from './images/xlsTile.svg';
import {
  BottomContainer,
  CardsGroupContainer,
  CheckboxesContainer,
  CollapsibleSidePanelCardWrapper,
  Container,
  DownloadBoxWrapper,
  FiltersContainer,
  Header,
  InProgressCardGroup,
  MiddleContainer,
  ScrollableWrapper,
  Separator,
  StyledCheckboxField,
  StyledDownloadBox,
  StyledEmptyPlaceholder,
  StyledMarkdown,
  StyledSeparator,
  TopContainer,
} from './knowledgeBaseV2.styled';
import {
  CategoryFilter,
  FavouriteItemType,
  LessonStatus,
  TagFilter,
  Thumbnail,
  ThumbnailList,
  ThumbnailType,
} from './knowledgeBaseV2.types';
import { KnowledgeBaseActions } from './redux/knowledgeBaseV2.reducer';
import { selectThumbnailLists } from './redux/knowledgeBaseV2.selectors';

const KnowledgeBaseV2: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const dispatch: Dispatch<AnyAction> = useDispatch();
  const thumbnailLists: ThumbnailList[] = useSelector(selectThumbnailLists) as ThumbnailList[];
  const [statusFilter, setStatusFilter] = useState<LessonStatus | undefined>(undefined);
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  const [itemToShowOnSidePanel, setItemToShowOnSidePanel]: UseState<Thumbnail | undefined> = useState<Thumbnail | undefined>();
  const [categoriesFilters, setCategoriesFilters] = useState<CategoryFilter[]>([]);
  const [tagFilters, setTagFilters] = useState<TagFilter[]>([]);
  const navigate: NavigateFunction = useNavigate();
  const isTablet: boolean = useMediaQuery({
    query: Breakpoint.Tablet,
  });

  const filterByCategory: (item: Thumbnail) => boolean = (item: Thumbnail): boolean => {
    if (categoriesFilters.length) {
      let matchingFilter: CategoryFilter;
      if ([ThumbnailType.File, ThumbnailType.Files].includes(item.type)) {
        matchingFilter = CategoryFilter.Files;
      } else if ([ThumbnailType.Lesson, ThumbnailType.LessonSlideScroll].includes(item.type)) {
        matchingFilter = CategoryFilter.ELearning;
      } else {
        matchingFilter = CategoryFilter.Video;
      }

      return !!categoriesFilters.find((filter: CategoryFilter): boolean => filter === matchingFilter);
    }

    return true;
  };

  const filterByTags: (item: Thumbnail) => boolean = (item: Thumbnail): boolean => !tagFilters.length
    || (item.cyclical && tagFilters.includes(TagFilter.Cyclical))
    || (item.required && tagFilters.includes(TagFilter.Required));

  const filterThumbnails: (thumbnails: Thumbnail[]) => Thumbnail[] = (thumbnails: Thumbnail[]): Thumbnail[] => thumbnails
    .filter((item: Thumbnail): boolean => !statusFilter || item.statusKey === statusFilter)
    .filter((item: Thumbnail): boolean => filterByCategory(item))
    .filter((item: Thumbnail): boolean => filterByTags(item));

  const inProgressMaterials: Thumbnail[] = useMemo(
    (): Thumbnail[] => {
      const favourites: Thumbnail[] = [];
      thumbnailLists.forEach((list: ThumbnailList): void => list.items.forEach(
        (item: Thumbnail): void => {
          if (item.statusKey === LessonStatus.InProgress) {
            favourites.push(item);
          }
        }
      ));

      return favourites;
    },
    [thumbnailLists]
  );

  const getCardImage: (item: Thumbnail) => string = (item: Thumbnail): string => {
    if (item.items?.length) {
      if (item.type === ThumbnailType.Files) {
        return `${AppConfig.content}/assets/img/files.jpg`;
      }

      return `${AppConfig.content}${item.items[0].image}` ?? '';
    } else if (item.type === ThumbnailType.File) {
      switch (item.url?.split('.').pop()) {
        case 'png': return PngTile;
        case 'jpg': return JpgTile;
        case 'docx': return DocTile;
        case 'pdf': return PdfTile;
        case 'xlsx': return XlsTile;
        case 'ppt': return PptTile;
      }
    }

    return `${AppConfig.content}${item.image}` ?? '';
  };

  const getFavouriteItemType: (itemType: ThumbnailType) => FavouriteItemType = (itemType: ThumbnailType): FavouriteItemType => {
    switch (itemType) {
      case ThumbnailType.Contents: return FavouriteItemType.ContentGroup;
      case ThumbnailType.Files: return FavouriteItemType.FileGroup;
      case ThumbnailType.Items: return FavouriteItemType.Competence;
      default: return FavouriteItemType.Content;
    }
  };

  const changeFavouriteState: (item: Thumbnail) => void = (item: Thumbnail): void => {
    if (item.favorite) {
      dispatch(CommonActions.removeFavouriteItem(getFavouriteItemType(item.type), item.id));
    } else {
      dispatch(CommonActions.addFavouriteItem(getFavouriteItemType(item.type), item.id));
    }
  };

  const handleOnCardClick: (item: Thumbnail) => void = (item: Thumbnail): void => {
    if ([ThumbnailType.Files, ThumbnailType.Contents].includes(item.type)) {
      setIsSidePanelOpen(true);
      setItemToShowOnSidePanel(item);
    } else if ([ThumbnailType.Lesson, ThumbnailType.LessonSlideScroll].includes(item.type)) {
      navigate(`/lekcje/${item.id}`);
    } else if (item.type === ThumbnailType.Video) {
      navigate(`/wideo/${item.id}`);
    }
  };

  const transformThumbnailIntoCardData: (item: Thumbnail) => CardData = (item: Thumbnail): CardData => ({
    linkData: item.type === ThumbnailType.File
      ? { externalUrl: item.url, fileName: item.name }
      : undefined,
    onCardClick: (): void => handleOnCardClick(item),
    onMultipleFilesDownload: item.type === ThumbnailType.Files
      ? (): void => downloadAll(item.name, item.items)
      : undefined,
    itemsInListNumber: item.items?.length,
    title: item.name,
    category: item.category,
    tags: [
      ...(item.required ? [t('competence.app.knowledgeBaseV2.cardTag.required')] : []),
      ...(item.cyclical ? [t('competence.app.knowledgeBaseV2.cardTag.cyclical')] : []),
    ],
    icons: [
      ...(item.completed ? [IconName.CheckmarkCircle] : []),
      ...(item.award ? [IconName.Award] : []),
    ],
    image: getCardImage(item),
    progress: item.progress && item.progress * 100,
    onIsLikedChange: (): void => changeFavouriteState(item),
    initialIsLiked: !!item.favorite,
  });

  const handleCheckboxChange: (filter: TagFilter) => void = (filter: TagFilter): void => {
    if (tagFilters.includes(filter)) {
      setTagFilters(tagFilters.filter((fil: TagFilter): boolean => fil !== filter));
    } else {
      setTagFilters([...tagFilters, filter]);
    }
  };

  useEffect((): void => {
    dispatch(KnowledgeBaseActions.getThumbnailListsAndTags());
  }, [dispatch]);

  return (
    <>
      <Container>
        <TopContainer>
          <ScrollableWrapper>
            <Categories
              allText={t('competence.app.knowledgeBaseV2.category.all')}
              categories={[
                { name: CategoryFilter.Video, label: t('competence.app.knowledgeBaseV2.category.video') },
                { name: CategoryFilter.Files, label: t('competence.app.knowledgeBaseV2.category.documents') },
                { name: CategoryFilter.ELearning, label: t('competence.app.knowledgeBaseV2.category.eLearning') },
              ]}
              onSelectedChange={(categories: Category[]): void =>
                setCategoriesFilters(categories.map((cat: Category): CategoryFilter => cat.name as CategoryFilter))
              }
            />
          </ScrollableWrapper>
          <Header variant={isTablet ? TypographyVariant.DisplayMD : TypographyVariant.DisplayXS}>
            <StyledMarkdown text={t('competence.app.knowledgeBaseV2.pageTitle')} />
          </Header>
          {!!isTablet && <Separator />}
        </TopContainer>
        <MiddleContainer>
          <ScrollableWrapper>
            <FiltersContainer>
              <Switch
                initialActiveOption=''
                onToggleOption={(option: string): void => setStatusFilter(option ? option as LessonStatus : undefined)}
                options={[
                  { name: '', label: t('competence.app.knowledgeBaseV2.switch.all') },
                  { name: LessonStatus.NotStarted, label: t('competence.app.knowledgeBaseV2.switch.notStarted') },
                  { name: LessonStatus.InProgress, label: t('competence.app.knowledgeBaseV2.switch.inProgress') },
                  { name: LessonStatus.Finished, label: t('competence.app.knowledgeBaseV2.switch.finished') },
                  { name: LessonStatus.Failed, label: t('competence.app.knowledgeBaseV2.switch.failed') },
                ]}
                variant={SwitchVariant.Dark}
              />
              {!!isTablet && (
                <CheckboxesContainer>
                  <StyledCheckboxField
                    checked={tagFilters.includes(TagFilter.Cyclical)}
                    id={TagFilter.Cyclical}
                    label={t('competence.app.knowledgeBaseV2.checkbox.cyclical')}
                    onChange={(): void => handleCheckboxChange(TagFilter.Cyclical)}
                    size={CheckboxSize.Medium}
                    type={CheckboxType.Checkbox}
                    variant={CheckboxVariant.SimpleText}
                  />
                  <StyledCheckboxField
                    checked={tagFilters.includes(TagFilter.Required)}
                    id={TagFilter.Required}
                    label={t('competence.app.knowledgeBaseV2.checkbox.required')}
                    onChange={(): void => handleCheckboxChange(TagFilter.Required)}
                    size={CheckboxSize.Medium}
                    type={CheckboxType.Checkbox}
                    variant={CheckboxVariant.SimpleText}
                  />
                </CheckboxesContainer>
              )}
            </FiltersContainer>
          </ScrollableWrapper>
          {!isTablet && (
            <CheckboxesContainer>
              <StyledCheckboxField
                checked={tagFilters.includes(TagFilter.Cyclical)}
                id={TagFilter.Cyclical}
                label={t('competence.app.knowledgeBaseV2.checkbox.cyclical')}
                onChange={(): void => handleCheckboxChange(TagFilter.Cyclical)}
                size={CheckboxSize.Medium}
                type={CheckboxType.Checkbox}
                variant={CheckboxVariant.SimpleText}
              />
              <StyledCheckboxField
                checked={tagFilters.includes(TagFilter.Required)}
                id={TagFilter.Required}
                label={t('competence.app.knowledgeBaseV2.checkbox.required')}
                onChange={(): void => handleCheckboxChange(TagFilter.Required)}
                size={CheckboxSize.Medium}
                type={CheckboxType.Checkbox}
                variant={CheckboxVariant.SimpleText}
              />
            </CheckboxesContainer>
          )}
          {!!filterThumbnails(inProgressMaterials).length && (
            <InProgressCardGroup>
              <CardsGroup
                cards={filterThumbnails(inProgressMaterials).map((item: Thumbnail): CardData => transformThumbnailIntoCardData(item))}
                notificationsNumber={filterThumbnails(inProgressMaterials).length}
                scrollUpText={t('competence.app.knowledgeBaseV2.button.toTheTop')}
                showMoreText={t('competence.app.knowledgeBaseV2.button.showMore')}
                title={t('competence.app.knowledgeBaseV2.inProgressSectionTitle')}
              />
            </InProgressCardGroup>
          )}
          {!thumbnailLists.some((thumbnailList: ThumbnailList): boolean => !!filterThumbnails(thumbnailList.items).length) && (
            <StyledEmptyPlaceholder image={Shape} text={t('competence.app.knowledgeBaseV2.noContent')} />
          )}
        </MiddleContainer>
        <BottomContainer>
          {thumbnailLists.map((thumbnailList: ThumbnailList): JSX.Element | null => {
            const filteredThumbnailList: Thumbnail[] = filterThumbnails(thumbnailList.items);

            return filteredThumbnailList.length
              ? (
                  <CardsGroupContainer key={thumbnailList.title}>
                    <CardsGroup
                      cards={filteredThumbnailList.map((item: Thumbnail): CardData => transformThumbnailIntoCardData(item))}
                      labelsTheme={LabelVariant.White}
                      notificationsNumber={thumbnailList.items.length}
                      title={thumbnailList.title}
                    />
                  </CardsGroupContainer>
                ) : null;
          })}
        </BottomContainer>
        <HelpBanner
          bgImage={isTablet ? DesktopImage : MobileImage}
          buttonIcon={IconName.EmailOutlined}
          buttonLabel={t('competence.app.knowledgeBaseV2.helpBanner.help')}
          externalUrl={`mailto:${AppConfig.platformContactEmail}`}
          headerText={t('competence.app.knowledgeBaseV2.helpBanner.needHelp')}
          supportingText={t('competence.app.knowledgeBaseV2.helpBanner.contactUs')}
        />
      </Container>
      {!!itemToShowOnSidePanel && (
        <CollapsibleSidePanel
          isOpen={isSidePanelOpen}
          onClose={(): void => setIsSidePanelOpen(false)}
          title={t(`competence.app.knowledgeBaseV2.sidePanelTitle.${itemToShowOnSidePanel.type === ThumbnailType.Files
            ? 'files'
            : 'videos'
          }`, { title: itemToShowOnSidePanel.name })}
        >
          {itemToShowOnSidePanel.items?.map((item: Thumbnail): JSX.Element => (
            item.type === ThumbnailType.File ? (
              <DownloadBoxWrapper key={item.id}>
                <StyledSeparator />
                <StyledDownloadBox
                  externalUrl={item.url ?? ''}
                  fileName={item.name}
                  image={getCardImage(item)}
                  supportingText=''
                />
              </DownloadBoxWrapper>
            ) : (
              <CollapsibleSidePanelCardWrapper key={item.id} onClick={(): void => handleOnCardClick(item)}>
                <Card
                  category={item.category}
                  image={getCardImage(item)}
                  isVideo
                  title={item.name}
                  variant={CardVariant.Horizontal}
                />
              </CollapsibleSidePanelCardWrapper>
            )
          ))}
        </CollapsibleSidePanel>
      )}
    </>
  );
};

export default KnowledgeBaseV2;
