import { StickyMenu, StickyMenuItem } from '@elearning-platform/ui';
import React from 'react';
import { Location, useLocation } from 'react-router';

import { Path } from '../../enums';

import { BottomNavItems } from './stickyMenuContainer.constants';
import { Container } from './stickyMenuContainer.styled';

export const StickyMenuContainer: React.FC = (): JSX.Element => {
  const { pathname }: Location = useLocation();
  const items: StickyMenuItem[] = BottomNavItems.map((item: StickyMenuItem): StickyMenuItem => ({
    ...item,
    current: (item.path === Path.Dashboard && pathname === Path.Main) || pathname.includes(item.path),
  }));

  return (
    <Container>
      <StickyMenu items={items} />
    </Container>
  );
};
