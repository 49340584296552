import { Footer } from "@elearning-platform/ui";
import React, { Component, useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import { test } from "ramda";
import ReactGA from "react-ga4";
import { Route, Routes } from "react-router-dom";
import { ThemeProvider as MuiThemeProvider } from "@mui/material";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/pl";
import "dayjs/locale/en";
import { datadogLogs } from "@datadog/browser-logs";

import { FavouriteToast } from "../components/favouritesList/favouriteToast/favouriteToast.component";
import { Regulations } from "../components/regulations/regulations.component";
import { RegulationsType } from "../components/regulations/regulations.constants";
import Search from "../modules/search/search.component";
import { selectUserLoggedIn } from "../userContext/redux/userContext.selectors";
import { CommonActions } from "../redux/common/common.reducer";
import { EmailVerification } from "../pages/emailVerification/emailVerification";
import { AuthView } from "../pages/authView/authView";
import { EventForm } from "../pages/eventForm/eventForm";
import { currentLanguage } from "../helpers/translations";
import { renderWhenTrue } from "../helpers/rendering";
import { UserRoleName } from "../userContext/userContext.constants";
import { getTheme } from "../theme/materialTheme";
import { AppConfig, Theme } from "../appConfig";
import { LoginWrapper, Login } from "../userContext";
import { Path } from "../enums";
import { getModuleComponent, ModuleType } from "../modules";
import Lesson from "../lesson";
import registerServiceWorker, { unregister } from "../registerServiceWorker";
import PasswordReset from "../userRegistration/passwordReset";

import { EditProfileReminder } from "./editProfileReminder/editProfileReminder.component";
import { RegulationsModal } from "./regulationsModal/regulationsModal.component";
import { GamificationBadgeModal } from "./gamificationBadgeModal/gamificationBadgeModal.component";
import { NotFoundPage } from "./notFoundPage/notFoundPage.component";
import { AgreementsModal } from "./agreementsModal/agreementsModal.component";
import { AmbassadorAccept } from "./ambassadorAccept/ambassadorAccept.component";
import { LogoutErrorModal } from "./logoutErrorModal/logoutErrorModal.component";
import { RefreshPopup } from "./refreshPopup/refreshPopup.component";
import { PasswordReminder } from "./passwordReminder/passwordReminder.component";
import { ChangePasswordModal } from "./changePasswordModal/changePasswordModal.component";
import { CookiePopup } from "./cookiePopup/cookiePopup.component";
import { actionCreators } from "./networkErrorModal/actions";
import MainFooter from "./mainFooter/mainFooter.component";
import OfflineToast from "./offlineToast/offlineToast.component";
import { StickyMenuContainer } from "./stickyMenuContainer/stickyMenuContainer.component";
import { SideMenuContainer } from "./sideMenuContainer/sideMenuContainer.component";
import { MobileNavigationMenu } from "./mobileNavigationMenu/mobileNavigationMenu.component";
import { TopMenuContainer } from "./topMenuContainer/topMenuContainer";
import MainHeader from "./mainHeader";
import MainNavigation from "./mainNavigation";
import NetworkErrorModal from "./networkErrorModal";
import { globalErrorHandler } from "./errorHandlers";
import {
  Wrapper,
  RightWrapper,
  MainContent,
  RemainingPlace,
  MobileHeader,
  SearchPopupWrapper,
} from "./app.styled";
import { FooterLinks } from "./app.constants";

class MainWrapper extends Component {
  constructor() {
    super();
    this.state = { mobileVisible: false };

    if (AppConfig.logsSystemClientToken) {
      datadogLogs.init({
        clientToken: AppConfig.logsSystemClientToken,
        site: "datadoghq.eu",
        service: AppConfig.platformName,
        forwardErrorsToLogs: true,
        sampleRate: 100,
      });
    }
    globalErrorHandler();

    if (AppConfig.GA_id) ReactGA.initialize(AppConfig.GA_id);
    dayjs.locale(currentLanguage);
    dayjs.extend(relativeTime);
  }

  toggleMobileMenu() {
    this.setState({ mobileVisible: !this.state.mobileVisible });
  }

  componentDidMount() {
    this.props.errorHandler();
  }

  renderModules() {
    return AppConfig.modules.map(({ urls, moduleName }) => {
      const Component = getModuleComponent(moduleName);
      return <Route key={moduleName} path={urls[0]} element={<Component />} />;
    });
  }

  render() {
    const { mobileVisible } = this.state;
    const { user, settings, profile, error, searchPopupActive } = this.props;

    document.oncontextmenu = () => {
      return false;
    }

    const renderOnboardingRoutes = renderWhenTrue(() => {
      const OnboardingDashboard = getModuleComponent(ModuleType.Onboarding);
      return (
        <>
          {Theme.ModernSignIn && (
            <>
              <Route exact path={Path.ConfirmEmail} element={<EmailVerification />} />
              <Route exact path={Path.EmailVerification} element={<EmailVerification />} />
            </>
          )}
          <Route exact path="/lekcje/:id" element={<Lesson />} />
          {this.renderModules()}
          <Route exact path="/" element={<OnboardingDashboard />} />
          <Route path="*" element={<NotFoundPage />} />
        </>
      );
    });

    const renderDefaultRoutes = renderWhenTrue(() => {
      const Dashboard = getModuleComponent(ModuleType.Dashboard);
      return (
        <>
          {Theme.ModernSignIn && (
            <>
              <Route exact path={Path.ConfirmEmail} element={<EmailVerification />} />
              <Route exact path={Path.EmailVerification} element={<EmailVerification />} />
            </>
          )}
          <Route exact path="/lekcje/:id" element={<Lesson />} />
          {this.renderModules()}
          <Route path={Path.EventForm} element={<EventForm />} />
          <Route exact path="/" element={<Dashboard />} />
          <Route path="*" element={<NotFoundPage />} />
        </>
      );
    });

    const renderChangePasswordModal = renderWhenTrue(() => <ChangePasswordModal />);
    const renderPasswordReminder = renderWhenTrue(() => <PasswordReminder />);
    const renderEditProfileReminder = renderWhenTrue(() => <EditProfileReminder />);
    const renderRegulationsModal = renderWhenTrue(() => <RegulationsModal />);
    const renderAgreementsModal = renderWhenTrue(() => <AgreementsModal />);

    if (!user) return <></>;

    return (
      <>
        <MobileHeader />
        {(!user.loggedIn || !settings.current) && (
          <Routes>
            {Theme.ModernEmailVerification && !user.loggedIn && (
              <>
                <Route path={Path.ConfirmEmail} element={<EmailVerification />} />
                <Route path={Path.EmailVerification} element={<EmailVerification />} />
              </>
            )}
            {Theme.ModernSignIn && !user.loggedIn && (
              <>
                <Route
                  path={Path.RemindPassword}
                  element={<PasswordReset activate={false} passwordRemind={true} />}
                />
                <Route path="*" element={<AuthView />} />
              </>
            )}
            <Route
              path="*"
              element={
                <LoginWrapper loggedIn={user.loggedIn}>
                  <Login />
                </LoginWrapper>
              }
            />
          </Routes>
        )}
        {user.loggedIn &&
          settings.currentBrand && (
          <Wrapper>
            {Theme.ModernNavigation ? (
              <SideMenuContainer />
            ) : (
              <MainNavigation
                settings={settings}
                mobileVisible={mobileVisible}
                menuToggle={this.toggleMobileMenu.bind(this)}
                availableRoutes={user.data.user.availableRoutes}
                isOnboardingUser={user.data.user.role.name === UserRoleName.Onboarding}
              />
            )}
            <NetworkErrorModal isOpen={error.errorNetwork} />
            <RightWrapper>
              {Theme.ModernNavigation ? (
                <TopMenuContainer />
              ) : (
                <MainHeader
                  settings={settings}
                  profile={profile}
                  menuToggle={this.toggleMobileMenu.bind(this)}
                  additionalBrands={user.data.user.brands}
                  token={user.data.token}
                  user={user}
                />
              )}
              <MainContent hidden={searchPopupActive}>
                <Routes>
                  <Route
                    exact
                    path="/polityka-prywatnosci"
                    element={<Regulations type={RegulationsType.PrivacyPolicy} />}
                  />
                  <Route
                    exact
                    path="/klauzula-informacyjna"
                    element={<Regulations type={RegulationsType.InfoClause} />}
                  />
                  <Route
                    exact
                    path="/regulamin"
                    element={<Regulations type={RegulationsType.Regulations} />}
                  />
                  <Route
                    exact
                    path="/regulamin-szkolen"
                    element={<Regulations type={RegulationsType.TrainingRegulations} />}
                  />
                  {renderDefaultRoutes(user.data.user.role.name !== UserRoleName.Onboarding)}
                  {renderOnboardingRoutes(user.data.user.role.name === UserRoleName.Onboarding)}
                </Routes>
                <RemainingPlace />
              </MainContent>
              {Theme.ModernFooter ? (
                <Footer name={AppConfig.platformName} links={FooterLinks} />
              ) : (
                <MainFooter />
              )}
              {Theme.ModernNavigation ? <StickyMenuContainer /> : <MobileNavigationMenu />}
            </RightWrapper>
            <OfflineToast />
            <FavouriteToast />
            {renderChangePasswordModal(user.data?.passwordExpirationInfo?.forceChange)}
            {renderPasswordReminder(user.data?.passwordExpirationInfo?.remindChange)}
            {renderEditProfileReminder(
              user.data.user.requireVerification
              && !user.data.user.requireLeaderTerm
              && !user.data?.passwordExpirationInfo?.forceChange
              && !/\/webinar\/\d+/.test(window.location.pathname)
            )}
            {renderRegulationsModal(
              !!user.data.user.requiredAgreement
              && !user.data?.passwordExpirationInfo?.forceChange
              && !["/regulamin", "/polityka-prywatnosci", "/klauzula-informacyjna", "/regulamin-szkolen"].includes(window.location.pathname)
            )}
            {renderAgreementsModal(
              !user.data.user.requiredAgreement
              && !user.data?.passwordExpirationInfo?.forceChange
              && !!user.data.user.missingAgreements?.length
            )}
            <GamificationBadgeModal />
            <AmbassadorAccept visible={user.data.user.requireLeaderTerm} />
            {searchPopupActive && (
              <SearchPopupWrapper>
                <Search />
              </SearchPopupWrapper>
            )}
          </Wrapper>
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  user: state.userContext,
  profile: state.profile,
  settings: state.appSettings,
  searchPopupActive: state.common.searchPopupActive,
  error: state.errorNetwork,
});

const mapDispatchToProps = (dispatch) => ({ errorHandler: () => dispatch(actionCreators.errorHandler(false)) });

const MainWrapperWithUser = connect(mapStateToProps, mapDispatchToProps)(MainWrapper);

const App = ({ currentBrandId, settingsBrands, commonBrands }) => {
  const dispatch = useDispatch();
  const userLoggedIn = useSelector(selectUserLoggedIn);
  const [theme, updateTheme] = useState(getTheme());
  const isAnonSurvey = test(/\/ankieta\//, window.location.pathname);
  const AnonSurvey = getModuleComponent(ModuleType.AnonSurvey);
  const [showRefreshPopup, setShowRefreshPopup] = useState(false);

  useEffect(() => {
    const userAgentString = navigator.userAgent;
    const chromeAgent = userAgentString.indexOf("Chrome") > -1;
    let safariAgent = userAgentString.indexOf("Safari") > -1;
    if (chromeAgent && safariAgent) {
      safariAgent = false;
    }

    if (safariAgent) {
      unregister();
    } else {
      registerServiceWorker({
        onUpdate: (registration) => {
          registration.waiting?.postMessage({ type: "SKIP_WAITING" });
          setShowRefreshPopup(true);
        },
      });
    }
  }, []);

  useEffect(() => {
    dispatch(CommonActions.fetchBrands());
  }, [dispatch]);

  useEffect(() => {
    const brand = settingsBrands.concat(commonBrands).find(({ id }) => id === currentBrandId);
    updateTheme(getTheme(brand));
  }, [currentBrandId, settingsBrands, commonBrands]);

  useEffect(() => {
    if (userLoggedIn) {
      dispatch(CommonActions.fetchNotifications());
      dispatch(CommonActions.fetchUserData());
    }
  }, [dispatch, userLoggedIn]);

  useEffect(() => {
    const scrollHeight = parseInt(sessionStorage.getItem(window.location.pathname));
    if (!isNaN(scrollHeight)) {
      scrollTo(0, scrollHeight);
      const scrollForBigScreen = setInterval(() => {
        scrollTo(0, scrollHeight);
        if (scrollHeight === 0 || window.scrollY !== 0) {
          clearInterval(scrollForBigScreen);
        }
      }, 100);
    }
  }, [window.location.pathname]);

  const setScrollValueStorage = () => {
    sessionStorage.setItem(window.location.pathname, window.pageYOffset);
  };

  useEffect(() => {
    window.addEventListener("scroll", setScrollValueStorage);

    return () => {
      window.removeEventListener("scroll", setScrollValueStorage);
    };
  }, []);

  return (
    <MuiThemeProvider theme={theme}>
      {showRefreshPopup && <RefreshPopup />}
      <CookiePopup />
      {isAnonSurvey ? (
        <AnonSurvey />
      ) : (
        <>
          <MainWrapperWithUser />
          <LogoutErrorModal />
        </>
      )}
    </MuiThemeProvider>
  );
}

const mapStateToAppProps = (state) => ({
  commonBrands: state.common.brands || [],
  settingsBrands: state.appSettings.brands || [],
  currentBrandId: state.appSettings.currentBrand,
});

export default withTranslation()(connect(mapStateToAppProps)(App));
