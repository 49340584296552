import { IconName } from "@elearning-platform/ui";

import { ReactComponent as ModuleIcon } from "../../images/news.svg";

export const Config = {
  moduleName: "news",
  dependencies: [],
  label: "news.pageTitle",
  Icon: ModuleIcon,
  urls: ["/news/*", "/news"],
  routeName: "api_news",
  routes: {
    init: "",
    entry: "/:id"
  },
  options: {},
  theme: {},
  modernIconName: IconName.PageUpdates,
};
