import { AnyAction, Reducer } from 'redux';
import { createActions, CreatedActions, createReducer } from 'reduxsauce';

import { deepClone } from '../../../helpers';
import { Thumbnail, ThumbnailList } from '../knowledgeBaseV2.types';

import { State } from './knowledgeBaseV2.types';

export const {
  Types: KnowledgeBaseTypes,
  Creators: KnowledgeBaseActions,
}: CreatedActions = createActions(
  {
    getThumbnailListsAndTags: null,
    getThumbnailListsSuccess: ['lists'],
    setListItemFavorite: ['itemId', 'favorite'],
  },
  { prefix: 'KNOWLEDGE_BASE/' }
);

const initState: State = {
  lists: [],
};

const setListItemFavorite: (state: State, { itemId, favorite }: { itemId: number; favorite: boolean }) => State = (
  state: State, { itemId, favorite }: { itemId: number; favorite: boolean }
): State => {
  if (!state.lists.length) {
    return state;
  }

  const listIndex: number = state.lists.findIndex(
    (list: ThumbnailList): boolean => !!list.items.find((item: Thumbnail): boolean => item.id === itemId)
  );
  if (listIndex === -1) {
    return state;
  }
  const itemIndex: number = state.lists[listIndex].items.findIndex((item: Thumbnail): boolean => item.id === itemId);
  const newState: State = deepClone(state);
  newState.lists[listIndex].items[itemIndex].favorite = favorite;

  return newState;
};

const getThumbnailListsSuccess: Reducer = (state: State, { lists }: AnyAction): State => ({
  ...state,
  lists,
});

export const reducer: Reducer = createReducer(initState, {
  [KnowledgeBaseTypes.GET_THUMBNAIL_LISTS_SUCCESS]: getThumbnailListsSuccess,
  [KnowledgeBaseTypes.SET_LIST_ITEM_FAVORITE]: setListItemFavorite,
});
