import { ReactComponent as ModuleIcon } from "./images/moduleIcon.svg";

export const Config = {
  moduleName: "anonSurvey",
  dependencies: [],
  label: "Anon surveys",
  Icon: ModuleIcon,
  urls: ["/ankieta/:id"],
  routeName: "",
  routes: {},
  options: {},
  theme: {}
};
