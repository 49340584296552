import { Breakpoint, Color, StyledComponent, Typography } from '@elearning-platform/ui';
import { styled } from 'styled-components';

export const Wrapper: StyledComponent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const EventContainer: StyledComponent = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  border-top: 1px solid ${Color.Gray300};
  border-right: 1px solid ${Color.Gray300};
`;

export const MainContainer: StyledComponent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;

  @media ${Breakpoint.Desktop} {
    justify-content: center;
  }

  @media ${Breakpoint.SmallDesktop} {
    flex-direction: row;
  }
`;

export const EventNameContainer: StyledComponent = styled.div`
  width: 100%;
  display: flex;
  padding: 55px 16px;
  justify-content: center;
  flex-direction: column;
  border-bottom: 1px solid ${Color.Gray300};

  @media ${Breakpoint.SmallDesktop} {
    padding: 62px 80px;
    min-height: 238px;
  }
`;

export const StyledTypography: StyledComponent = styled(Typography)`
  color: ${Color.Gray700};
`;

export const EventDetailsContainer: StyledComponent = styled.div`
  width: 100%;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media ${Breakpoint.SmallDesktop} {
    flex-direction: row;
    padding: 40px 80px;
    gap: 80px;
  }
`;

export const ImageContainer: StyledComponent = styled.div``;

export const StyledEventName: StyledComponent = styled(Typography)`
  color: ${Color.Gray500};
`;

export const StyledEventDetails: StyledComponent = styled(Typography)`
  color: ${Color.Brand300};
`;

export const DetailWrapper: StyledComponent = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;

  @media ${Breakpoint.SmallDesktop} {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

export const StyledSubheader: StyledComponent = styled(Typography)`
  color: ${Color.Gray500};
`;

export const Image: StyledComponent = styled.img`
  width: 100%;
  max-width: 1024px;

  @media ${Breakpoint.SmallDesktop} {
    max-width: 590px;
  }
`;
