import { Breakpoint, Color, Input, StyledComponent, SwitchFrame } from '@elearning-platform/ui';
import { styled } from 'styled-components';

export const FormContainer: StyledComponent = styled.form`
  max-width: 1024px;
  width: 100%;
  border-right: 1px solid ${Color.Gray300};
`;

export const Container: StyledComponent = styled.div`
  width: 100%;
  max-width: 1280px;
  display: flex;
  justify-content: flex-start;
  border-top: 1px solid ${Color.Gray300};

  @media ${Breakpoint.SmallDesktop} {
    justify-content: center;

    ${FormContainer} {
      border-left: none;
    }
  }

  @media ${Breakpoint.Desktop} {
    border-top: none;
  }
`;

export const FormWrapper: StyledComponent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 40px 16px 48px 16px;
  border-top: none;

  @media ${Breakpoint.SmallDesktop} {
    padding: 40px 80px;
  }

  @media ${Breakpoint.Desktop} {
    border-top: 1px solid ${Color.Gray300};
  }
`;

export const StyledInput: StyledComponent = styled(Input)``;

export const Wrapper: StyledComponent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;

  ${StyledInput} {
    flex: 1;
  }

  @media ${Breakpoint.SmallDesktop} {
    flex-direction: row;
  }
`;

export const SupervisorSection: StyledComponent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const UserDataSection: StyledComponent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const StyledSwitchFrame: StyledComponent = styled(SwitchFrame)``;

export const AddonsSection: StyledComponent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  ${StyledSwitchFrame} {
    flex-shrink: 1;
  }
`;

export const ButtonsContainer: StyledComponent = styled.div`
  width: 100%;
  padding: 28px 16px;
  border-top: 1px solid ${Color.Gray300};
  border-bottom: 1px solid ${Color.Gray300};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  @media ${Breakpoint.SmallDesktop} {
    flex-direction: row;
    padding: 40px 80px;
    gap: 40px
  }
`;

export const ShapeContainer: StyledComponent = styled.div`
  max-width: 413px;
  width: 100%;
  max-height: 528px;
  border-right: 1px solid ${Color.Gray300};
  border-bottom: 1px solid ${Color.Gray300};
`;

export const StyledShape: StyledComponent = styled.img`
  width: 100%;
  height: 100%;

  @media ${Breakpoint.Desktop} {
    border-top: 1px solid ${Color.Gray300};
  }
`;

export const MobileShape: StyledComponent = styled.div`
  height: 40px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    width: 50%;
    height: 100%;
    border-right: 1px solid ${Color.Gray300};
    top: 0;
    left: 0;
  }
`;
