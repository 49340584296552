import { Color, StyledComponent, Typography } from '@elearning-platform/ui';
import { css, RuleSet, styled } from 'styled-components';

import { FooterVariant } from './footer.enums';
import { ContainerProps } from './footer.types';

export const FooterText: StyledComponent<typeof Typography> = styled(Typography)`
  color: ${Color.Gray400};
`;

const footerVariants: { [_ in FooterVariant]: RuleSet<ContainerProps> } = {
  [FooterVariant.Success]: css`
    background-color: ${Color.Black100};
  `,
  [FooterVariant.Fail]: css`
   background-color: ${Color.Gray700};
  `,
  [FooterVariant.Action]: css`
    background-color: ${Color.Gray100};

    ${FooterText} {
      color: ${Color.Gray800};
    }
   `,
};

export const Container: StyledComponent<ContainerProps> = styled.footer<ContainerProps>`
  width: 100%;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ $variant }: ContainerProps): RuleSet<ContainerProps> => footerVariants[$variant]}
`;
