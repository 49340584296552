import { ReactComponent as ModuleIcon } from "../../images/onboarding.svg";

export const Config = {
  moduleName: "onboarding",
  dependencies: [],
  label: "onboarding.pageTitle",
  Icon: ModuleIcon,
  urls: ["/onboarding"],
  routeName: "api_onboarding_assign",
  routes: {},
  options: {},
  theme: {},
};
