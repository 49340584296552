import { Breakpoint, Color, StyledComponent } from '@elearning-platform/ui';
import { styled } from 'styled-components';

export const StyledBreadcrumb: StyledComponent = styled.div`
  width: 100%;
  height: 58px;
  display: flex;
  padding: 20px;
  border-top: 1px solid ${Color.Gray300};

  @media ${Breakpoint.SmallDesktop} {
    border-top: none;
  }
`;
