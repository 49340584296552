import { AnyAction, Reducer } from 'redux';
import { createReducer } from 'reduxsauce';

import { EventFormTypes } from './eventForm.actions';
import { initState } from './eventForm.state';
import { State } from './eventForm.types';

const setEventDetails: Reducer = (state: State, { eventDetails }: AnyAction): State => ({
  ...state,
  eventDetails,
});

const setUserData: Reducer = (state: State, { userData }: AnyAction): State => ({
  ...state,
  userData,
});

export const reducer: Reducer = createReducer(initState, {
  [EventFormTypes.SET_EVENT_DETAILS]: setEventDetails,
  [EventFormTypes.SET_USER_DATA]: setUserData,
});
