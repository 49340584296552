import translationPL from "./pl/default.json";
import translationsGlobalPl from './resources/pl.json';
import translationsGlobalEn from './resources/en.json';
import rodoInformationPL from "./pl/rodoInformation.json";
import translationEN from "./en/default.json";
import rodoInformationEN from "./en/rodoInformation.json";

export const LanguageTheme = {
  Multi: "multi",
  PL: "pl",
};

export const ResourcesMultilingual = {
  pl: {
    translation: { ...translationPL, ...translationsGlobalPl },
    rodoInformation: rodoInformationPL,
  },
  en: {
    translation: { ...translationEN, ...translationsGlobalEn },
    rodoInformation: rodoInformationEN,
  },
};

export const ResourcesPL = {
  pl: {
    translation: { ...translationPL, ...translationsGlobalPl },
    rodoInformation: rodoInformationPL,
  },
};
