import { Breakpoint, StyledComponent, ZIndex } from '@elearning-platform/ui';
import { styled } from 'styled-components';

export const Container: StyledComponent = styled.div`
  display: none;

  @media ${Breakpoint.SmallDesktop} {
    display: block;
    position: fixed;
    left: 0;
    z-index: ${ZIndex.NavMenu};
  }
`;
