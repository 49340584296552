export const CoordsInsurance = [
  [
    { x: 128, y: 20 },
    { x: 128, y: 44 },
    { x: 128, y: 68 },
    { x: 152, y: 68 },
    { x: 152, y: 92 },
    { x: 152, y: 116 },
    { x: 152, y: 140 },
    { x: 128, y: 140 },
    { x: 128, y: 164 },
    { x: 128, y: 188 },
    { x: 128, y: 212 },
    { x: 152, y: 212 },
    { x: 176, y: 212 },
    { x: 220, y: 181 },
  ],
  [
    { x: 20, y: 212 },
    { x: 44, y: 212 },
    { x: 68, y: 212 },
    { x: 92, y: 212 },
    { x: 92, y: 188 },
    { x: 92, y: 164 },
    { x: 68, y: 164 },
    { x: 44, y: 164 },
    { x: 44, y: 140 },
    { x: 11, y: 30},
  ],
  [
    { x: 42, y: 321 },
    { x: 42, y: 297 },
    { x: 66, y: 297 },
    { x: 90, y: 297 },
    { x: 114, y: 297 },
    { x: 138, y: 297 },
    { x: 162, y: 297 },
    { x: 162, y: 273 },
    { x: 162, y: 249 },
    { x: 162, y: 225 },
    { x: 162, y: 201 },
    { x: 162, y: 177 },
    { x: 162, y: 153 },
    { x: 162, y: 129 },
    { x: 186, y: 129 },
    { x: 236, y: 96 },
  ],
  [
    { x: 28, y: 115 },
    { x: 52, y: 115 },
    { x: 76, y: 115 },
    { x: 76, y: 139 },
    { x: 76, y: 163 },
    { x: 76, y: 187 },
    { x: 52, y: 187 },
    { x: 28, y: 187 },
    { x: 28, y: 211 },
    { x: 28, y: 235 },
    { x: 52, y: 235 },
    { x: 20, y: 269 },
  ],
  [
    { x: 50, y: 20 },
    { x: 50, y: 44 },
    { x: 74, y: 44 },
    { x: 98, y: 44 },
    { x: 122, y: 44 },
    { x: 146, y: 44 },
    { x: 146, y: 68 },
    { x: 146, y: 92 },
    { x: 170, y: 92 },
    { x: 170, y: 116 },
    { x: 194, y: 116 },
    { x: 218, y: 116 },
    { x: 242, y: 116 },
    { x: 186, y: 160 },
  ],
];
