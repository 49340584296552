import { createSelector, Selector } from 'reselect';

import { GlobalState, State } from './eventForm.types';

export const selectEventFormDomain: ((data: GlobalState) => State) = (data: GlobalState): State => data.eventDetails;

export const selectEventDetails: Selector = createSelector(
  selectEventFormDomain, (data: State): State['eventDetails'] => data.eventDetails
);

export const selectUserInfo: Selector = createSelector(
  selectEventFormDomain, (data: State): State['userData'] => data.userData
);
