import { createActions, CreatedActions } from 'reduxsauce';

const { Types: EventFormTypes, Creators: EventFormActions }: CreatedActions = createActions({
  getEventDetails: ['id'],
  setEventDetails: ['eventDetails'],
  getUserData: null,
  setUserData: ['userData'],
  sendEventForm: ['eventId', 'formData', 'onFinish'],
});

export { EventFormTypes, EventFormActions };
