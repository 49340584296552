import {
  Breakpoint,
  CheckboxField,
  Color,
  DownloadBox,
  EmptyPlaceholder,
  FontWeight,
  Markdown,
  StyledComponent,
  Typography,
} from '@elearning-platform/ui';
import { styled } from 'styled-components';

export const Container: StyledComponent = styled.div``;

export const MiddleContainer: StyledComponent = styled.div`

  @media ${Breakpoint.Tablet} {
    padding: 0 80px;
  }
`;

export const BottomContainer: StyledComponent = styled.div`
  @media ${Breakpoint.Tablet} {
    padding: 0 80px;
  }

  background-color: ${Color.Gray100};
  padding: 0 0 0 16px;
`;

export const ScrollableWrapper: StyledComponent = styled.div`
  overflow-x: scroll;
  -ms-overflow-style: none;
  overflow-y: scroll;
  scrollbar-width: none;
  max-width: 100%;
  padding: 0 16px;

  @media ${Breakpoint.Tablet} {
    max-width: calc(100vw - 160px);
    padding: 0;
  }
`;

export const Separator: StyledComponent = styled.div`
  background-color: ${Color.Gray300};
  height: 1px;
  width: 100%;
`;

export const TopContainer: StyledComponent = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 60px;
  padding: 60px 0 32px;
  border-bottom: 1px solid ${Color.Gray300};

  @media ${Breakpoint.Tablet} {
    flex-direction: column;
    gap: 40px;
    padding: 40px 80px;
    border-bottom: none;
  }
`;

export const Header: StyledComponent = styled(Typography)`
  max-width: 65%;
  padding-left: 16px;

  @media ${Breakpoint.Tablet} {
    max-width: 550px;
    padding-left: 0;
  }
`;

export const StyledMarkdown: StyledComponent = styled(Markdown)`
  color: ${Color.Gray700};

  strong {
    color: ${Color.Gray500};
    font-weight: ${FontWeight.Regular};
  }
`;

export const Span: StyledComponent = styled.span`
  color: ${Color.Gray500};
`;

export const FiltersContainer: StyledComponent = styled.div`
  flex-direction: column;
  align-items: start;
  padding: 40px 0 32px;
  display: flex;
  justify-content: space-between;
  gap: 32px;

  @media ${Breakpoint.Tablet} {
    align-items: center;
    flex-direction: row;
    padding: 19px 0;
  }
`;

export const CheckboxesContainer: StyledComponent = styled.div`
  display: flex;
  padding: 0 16px;

  @media ${Breakpoint.Tablet} {
    padding: 0;
  }
`;

export const StyledEmptyPlaceholder: StyledComponent = styled(EmptyPlaceholder)`
  margin: 40px 0 80px;
`;

export const StyledCheckboxField: StyledComponent = styled(CheckboxField)`
  align-items: center;
  padding: 8px;
  min-width: 108px;
  height: 36px;
`;

export const CardsGroupContainer: StyledComponent = styled.div`
  padding: 40px 0;

  &:not(:last-child) {
    border-bottom: 1px solid ${Color.Gray300};
  }

  @media ${Breakpoint.Tablet} {
    padding: 80px 0;
  }
`;

export const InProgressCardGroup: StyledComponent = styled.div`
  padding: 56px 16px;

  @media ${Breakpoint.Tablet} {
    padding: 40px 0 80px;
  }
`;

export const DownloadBoxWrapper: StyledComponent = styled.div``;

export const StyledSeparator: StyledComponent = styled(Separator)`
  margin: 0;

  @media ${Breakpoint.Tablet} {
    margin: 0 40px;
  }
`;

export const StyledDownloadBox: StyledComponent = styled(DownloadBox)`
  background-color: ${Color.Gray100};
`;

export const CollapsibleSidePanelCardWrapper: StyledComponent = styled.div`
  padding: 20px 0;
  border-top: 1px solid ${Color.Gray300};
  margin: 0;

  @media ${Breakpoint.Tablet} {
    margin: 0 40px;
  }
`;
