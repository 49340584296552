import React from 'react';

import { Form } from './components/form/form.component';
import { Header } from './components/header/header.component';
import { Container, Wrapper } from './eventForm.styled';

export const EventForm: React.FC = (): JSX.Element => (
  <Wrapper>
    <Container>
      <Header />
      <Form />
    </Container>
  </Wrapper>
);
