import { ShapeVariant } from '@elearning-platform/ui';
import React, { useEffect } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Params, useParams } from 'react-router';
import { AnyAction, Dispatch } from 'redux';

import { ActionView } from './components/actionView/actionView.component';
import { Success } from './components/success/success.component';
import ConfirmEmailBgLarge from './images/confirmBgLarge.jpg';
import ConfirmEmailBgSmall from './images/confirmBgSmall.jpg';
import EmailVerifiedBgLarge from './images/verifiedBgLarge.jpg';
import EmailVerifiedBgSmall from './images/verifiedBgSmall.jpg';
import { EmailVerificationActions } from './redux/emailVerification.actions';
import { selectIsTokenValid } from './redux/emailVerification.selectors';

export const EmailVerification: React.FC = (): JSX.Element => {
  const { token }: Params = useParams();
  const { t }: TransProps<never> = useTranslation();
  const isTokenValid: boolean | null = useSelector(selectIsTokenValid) as boolean | null;
  const isWaitingForAction: boolean = isTokenValid === null;
  const dispatch: Dispatch<AnyAction> = useDispatch();

  useEffect((): () => void => (
    (): void => {
      dispatch(EmailVerificationActions.clearData());
    }
  ), [dispatch]);

  const onConfirm: () => void = (): void => {
    if (token) {
      dispatch(EmailVerificationActions.checkToken(token));
    }
  };

  if (!token) {
    return (
      <Success
        headline={t('emailVerification.confirmRegistration')}
        imageLarge={ConfirmEmailBgLarge}
        imageSmall={ConfirmEmailBgSmall}
        primaryText={t('emailVerification.emailSent')}
        secondaryText={t('emailVerification.clickLink')}
        shape={ShapeVariant.Circle1}
      />
    );
  }

  if (isWaitingForAction) {
    return (
      <ActionView
        buttonLabel={t('emailVerification.confirm')}
        onConfirm={onConfirm}
        primaryText={t('emailVerification.confirmYour')}
        secondaryText={t('emailVerification.registration')}
        supportingText={t('emailVerification.clickToConfirm')}
      />
    );
  }

  if (isTokenValid) {
    return (
      <Success
        headline={t('emailVerification.registrationConfirmed')}
        imageLarge={EmailVerifiedBgLarge}
        imageSmall={EmailVerifiedBgSmall}
        primaryText={t('emailVerification.requestReceived')}
        secondaryText={t('emailVerification.accountConfirmation')}
        shape={ShapeVariant.Cage}
      />
    );
  } else {
    return (
      <ActionView
        buttonLabel={t('emailVerification.mainPage')}
        dark
        primaryText={t('emailVerification.yourLink')}
        secondaryText={t('emailVerification.expired')}
        supportingText={t('emailVerification.contactSupervisor')}
      />
    );
  }
};
