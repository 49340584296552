import { Breakpoint, FontWeight, TypographyVariant } from '@elearning-platform/ui';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Params, useParams } from 'react-router';
import { AnyAction, Dispatch } from 'redux';

import { EventDetails } from '../../eventForm.types';
import CarImage from '../../images/car.png';
import { EventFormActions, selectEventDetails } from '../../redux';
import { Breadcrumb } from '../breadcrumb/breadcrumb.component';

import {
  DetailWrapper,
  EventContainer,
  EventDetailsContainer,
  EventNameContainer,
  Image,
  ImageContainer,
  MainContainer,
  StyledEventDetails,
  StyledEventName,
  StyledSubheader,
  StyledTypography,
  Wrapper,
} from './header.styled';

export const Header: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const dispatch: Dispatch<AnyAction> = useDispatch();
  const eventData: EventDetails | null = useSelector(selectEventDetails) as EventDetails | null;
  const { id }: Params = useParams();
  const isSmallDesktop: boolean = useMediaQuery({
    query: Breakpoint.SmallDesktop,
  });

  useEffect((): void => {
    dispatch(EventFormActions.getEventDetails(id));
  }, [id, dispatch]);

  return (
    <Wrapper>
      {!!isSmallDesktop && <Breadcrumb />}
      <MainContainer>
        <EventContainer>
          <EventNameContainer>
            <StyledTypography
              variant={isSmallDesktop ? TypographyVariant.DisplaySM : TypographyVariant.DisplayXS}
            >
              {t('competence.app.eventForm.header.formTitle')}
            </StyledTypography>
            {!!eventData?.course?.name && (
              <StyledEventName variant={isSmallDesktop ? TypographyVariant.DisplaySM : TypographyVariant.DisplayXS}>
                {eventData.course.name}
              </StyledEventName>
            )}
          </EventNameContainer>
          <EventDetailsContainer>
            {!!eventData?.date && (
              <>
                <DetailWrapper>
                  <StyledEventDetails variant={TypographyVariant.DisplayXS}>
                    {!!eventData?.date && dayjs.unix(eventData.date.from).format('DD.MM.YYYY')}
                  </StyledEventDetails>
                  <StyledSubheader fontWeight={FontWeight.Bold} variant={TypographyVariant.Subheader}>
                    {t('competence.app.eventForm.header.startDate')}
                  </StyledSubheader>
                </DetailWrapper>
                {/* TODO: change to custom hook when available */}
                {!dayjs.unix(eventData.date.from).isSame(dayjs.unix(eventData.date.to), 'day') && (
                  <DetailWrapper>
                    <StyledEventDetails variant={TypographyVariant.DisplayXS}>
                      {!!eventData?.date && dayjs.unix(eventData.date.to).format('DD.MM.YYYY')}
                    </StyledEventDetails>
                    <StyledSubheader fontWeight={FontWeight.Bold} variant={TypographyVariant.Subheader}>
                      {t('competence.app.eventForm.header.endDate')}
                    </StyledSubheader>
                  </DetailWrapper>
                )}
              </>
            )}
            {!!eventData?.location?.city && (
              <DetailWrapper>
                <StyledEventDetails variant={TypographyVariant.DisplayXS}>
                  {eventData.location.city}
                </StyledEventDetails>
                <StyledSubheader fontWeight={FontWeight.Bold} variant={TypographyVariant.Subheader}>
                  {t('competence.app.eventForm.header.location')}
                </StyledSubheader>
              </DetailWrapper>
            )}
          </EventDetailsContainer>
        </EventContainer>
        <ImageContainer>
          <Image alt={t('competence.app.eventForm.header.car')} src={CarImage} />
          {!isSmallDesktop && <Breadcrumb />}
        </ImageContainer>
      </MainContainer>
    </Wrapper>
  );
};
