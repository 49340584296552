import { all } from 'ramda';
import { takeLatest } from 'redux-saga/effects';

import { EventFormTypes } from './eventForm.actions';
import { enrollEvent, fetchEventDetails, fetchUserInfo } from './eventForm.sagas';

export function* watchEventForm() {
  yield all([
    yield takeLatest(EventFormTypes.GET_EVENT_DETAILS, fetchEventDetails),
    yield takeLatest(EventFormTypes.GET_USER_DATA, fetchUserInfo),
    yield takeLatest(EventFormTypes.SEND_EVENT_FORM, enrollEvent)
  ]);
}
