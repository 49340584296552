import { Breakpoint, StyledComponent, ZIndex } from '@elearning-platform/ui';
import { styled } from 'styled-components';

export const Container: StyledComponent = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${ZIndex.NavMenu};

  @media ${Breakpoint.SmallDesktop} {
    display: none;
  }
`;
