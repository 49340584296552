import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "styled-components";
import MediaQuery from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import removeMd from "remove-markdown";

import { ItemType } from "../../components/thumbnail/thumbnail.constants";
import { CommonActions } from "../../redux/common/common.reducer";
import { selectNewsRouteAvailable } from "../../userContext/redux/userContext.selectors";
import NetworkErrorModal from "../../app/networkErrorModal";
import { OfflineHandler } from "../../helpers/connectionHandler";
import { ContentContainer, Icon, Thumbnail } from "../../UIElements";
import { breakpoints } from "../../UIGlobals";
import { Color } from "../../theme";
import { useModuleConfig, ModuleType } from "../";

import NewsImage from "./images/news.png";
import { SearchActions } from "./redux/search.reducer";
import { selectData, selectNewsData } from "./redux/search.selectors";
import {
  SearchBarContainer,
  SearchIcon,
  SearchBarInput,
  SearchBarResults,
  CloseButton,
  CloseIcon,
  ThumbnailRow,
  ThumbnailDetails,
  ThumbnailDetailsContent,
  ThumbnailTitle,
  ThumbnailCategory,
  ThumbnailDescription,
  AuthorName,
} from "./search.styled";
import { ResultType } from "./search.constants";

const Search = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const config = useModuleConfig(ModuleType.Search);
  const currentLocationPathname = useRef(location.pathname);
  const inputRef = useRef();
  const newsAvailable = useSelector(selectNewsRouteAvailable);
  const data = useSelector(selectData);
  const newsData = useSelector(selectNewsData);
  const [searchValue, setSearchValue] = useState("");
  const [searchEditable, setSearchEditable] = useState(false);

  useEffect(() => {
    if (location?.search) {
      let searchQuery = decodeURIComponent(location.search.substring(1));

      if (searchQuery.includes("slide=")) {
        searchQuery = "";
      }

      if (searchQuery.length > 2) {
        dispatch(SearchActions.getData(searchQuery));

        if (newsAvailable) {
          dispatch(SearchActions.getNewsData(searchQuery));
        }
      }

      setSearchEditable(false);
      setSearchValue(searchQuery);
    }

    if (location.state?.competence) {
      dispatch(SearchActions.getData(location.state.competence));

      if (newsAvailable) {
        dispatch(SearchActions.getNewsData(location.state.competence));
      }

      setSearchEditable(false);
      setSearchValue(location.state.competence);
    }

    if (inputRef.current) inputRef.current.focus();

    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "unset";
      dispatch(SearchActions.cleanData());
    };
  }, []);

  const handleCloseSearchPopup = () => {
    dispatch(CommonActions.setSearchPopupActive(false));
  };

  useEffect(() => {
    if (location.pathname !== currentLocationPathname.current) {
      handleCloseSearchPopup();
    }
  }, [location.pathname]);

  const handleSearchValueChange = ({ target }) => {
    if (target.value.length > 2) {
      dispatch(SearchActions.getData(target.value));

      if (newsAvailable) {
        dispatch(SearchActions.getNewsData(target.value));
      }
    }

    setSearchEditable(true);
    setSearchValue(target.value);
  };

  const redirectToThumbnailURL = (result) => {
    if (result.type === ResultType.Download) {
      window.open(result.url, "_blank");
    } else if (result.type === ResultType.News) {
      navigate(`/news/${result.id}`);
    } else if (result.type !== ResultType.Files && result.type !== ResultType.ContentGroup) {
      navigate(result.url);
    }
  };

  return (
    <ThemeProvider theme={config.theme}>
      <ContentContainer background="almostWhite" minHeight="calc(100vh - 280px)">
        <OfflineHandler>
          <NetworkErrorModal isOpen />
        </OfflineHandler>
        <MediaQuery query={`(${breakpoints().maxM})`}>
          <SearchBarContainer height="45px">
            <SearchIcon size={17} />
            <SearchBarInput
              placeholder={t("search.search.searchInPlatform")}
              value={searchValue}
              onChange={handleSearchValueChange}
              ref={inputRef}
              fontSize="15px"
              width="100%"
              autoFocus
            />
          </SearchBarContainer>
          <SearchBarResults>
            {searchEditable && searchValue.length > 2 && (
              `${t("search.search.found")} ${data.length + newsData.length} ${t("search.search.results")}`
            )}
          </SearchBarResults>
          <CloseButton onClick={handleCloseSearchPopup}>
            <CloseIcon />
          </CloseButton>
          {data.map((result, index) => {
            const isFile = result.type === ResultType.Download;
            const isFilesGroup = result.type === ResultType.Files;
            const imagePath = result.image || result.dto?.items[0]?.image
            const isContent = result.type === ResultType.Content;
            const categoryLabel = isFile || isFilesGroup
              ? t("search.search.downloadFiles")
              : isContent
                ? t("search.search.elearning")
                : t("search.search.training");

            return (
              <ThumbnailRow key={index}>
                <Thumbnail
                  title={result.name}
                  hideTitle
                  containerWidth="150px"
                  items={result.dto?.items?.length || null}
                  itemsType={isFilesGroup ? ItemType.Files : ItemType.Video}
                  uri={
                    isFile || isFilesGroup
                      ? "/assets/img/files.jpg"
                      : imagePath
                  }
                  action={() => redirectToThumbnailURL(result)}
                  controlledHeight
                  itemsDetails={result.dto?.items}
                  isSearchView
                />
                <ThumbnailDetails>
                  <ThumbnailDetailsContent>
                    <ThumbnailTitle
                      disabled={result.type === ResultType.Files || result.type === ResultType.ContentGroup}
                      onClick={() => redirectToThumbnailURL(result)}>
                      {result.name}
                    </ThumbnailTitle>
                    <ThumbnailCategory>{categoryLabel}</ThumbnailCategory>
                    <ThumbnailDescription>
                      {result.description}
                    </ThumbnailDescription>
                  </ThumbnailDetailsContent>
                </ThumbnailDetails>
              </ThumbnailRow>
            );
          })}
          {newsData.map((result, index) => (
            <ThumbnailRow key={index}>
              <Thumbnail
                title={result.name}
                hideTitle
                containerWidth="150px"
                localImage={NewsImage}
                action={() => redirectToThumbnailURL(result)}
                isSearchView
              />
              <ThumbnailDetails>
                <ThumbnailDetailsContent>
                  <ThumbnailTitle onClick={() => redirectToThumbnailURL(result)}>
                    {result.name}
                    <AuthorName>
                      {` | ${result.dto.publisher.firstName} ${result.dto.publisher.lastName}`}
                    </AuthorName>
                  </ThumbnailTitle>
                  <ThumbnailCategory>{t("search.search.news")}</ThumbnailCategory>
                  <ThumbnailDescription>{result.description}</ThumbnailDescription>
                </ThumbnailDetailsContent>
              </ThumbnailDetails>
            </ThumbnailRow>
          ))}
        </MediaQuery>
        <MediaQuery query={`(${breakpoints().minM})`}>
          <SearchBarContainer>
            <SearchIcon />
            <SearchBarInput
              placeholder={t("search.search.searchInPlatform")}
              value={searchValue}
              onChange={handleSearchValueChange}
              ref={inputRef}
              fontSize="24px"
              autoFocus
            />
            <SearchBarResults>
              {searchEditable && searchValue.length > 2 && (
                `${t("search.search.found")} ${data.length + newsData.length} ${t("search.search.results")}`
              )}
            </SearchBarResults>
            <CloseButton onClick={handleCloseSearchPopup}>
              <CloseIcon />
            </CloseButton>
          </SearchBarContainer>
          {data.map((result, index) => {
            const isFile = result.type === ResultType.Download;
            const isFilesGroup = result.type === ResultType.Files;
            const imagePath = result.image || result.dto?.items[0]?.image
            const isContent = result.type === ResultType.Content;
            const categoryLabel = isFile || isFilesGroup
              ? t("search.search.downloadFiles")
              : isContent
                ? t("search.search.elearning")
                : t("search.search.training");

            return (
              <ThumbnailRow key={index}>
                <Thumbnail
                  title={result.name}
                  hideTitle
                  items={result.dto?.items?.length || null}
                  itemsType={isFilesGroup ? ItemType.Files : ItemType.Video}
                  uri={
                    isFile || isFilesGroup
                      ? "/assets/img/files.jpg"
                      : imagePath
                  }
                  action={() => redirectToThumbnailURL(result)}
                  controlledHeight
                  width="285px"
                  itemsDetails={result.dto?.items}
                  isSearchView
                />
                <ThumbnailDetails>
                  <ThumbnailDetailsContent>
                    <ThumbnailTitle
                      disabled={result.type === ResultType.Files || result.type === ResultType.ContentGroup}
                      onClick={() => redirectToThumbnailURL(result)}
                    >
                      {result.name}
                    </ThumbnailTitle>
                    <ThumbnailCategory>
                      {result.type !== ResultType.Download && (
                        <Icon
                          icon={isContent ? "dashboard_files" : "training_dashboard"}
                          color={Color.gray3}
                          size={12}
                        />
                      )}
                      {categoryLabel}
                    </ThumbnailCategory>
                    <ThumbnailDescription>{result.description}</ThumbnailDescription>
                  </ThumbnailDetailsContent>
                </ThumbnailDetails>
              </ThumbnailRow>
            );
          })}
          {newsData.map((result, index) => (
            <ThumbnailRow key={index}>
              <Thumbnail
                title={result.name}
                hideTitle
                localImage={NewsImage}
                action={() => redirectToThumbnailURL(result)}
                width="285px"
                isSearchView
              />
              <ThumbnailDetails>
                <ThumbnailDetailsContent>
                  <ThumbnailTitle onClick={() => redirectToThumbnailURL(result)}>
                    {result.name}
                    <AuthorName>
                      {` | ${result.dto.publisher.firstName} ${result.dto.publisher.lastName}`}
                    </AuthorName>
                  </ThumbnailTitle>
                  <ThumbnailCategory>
                    <Icon icon="news_new" color={Color.gray3} size={12} />
                    {t("search.search.news")}
                  </ThumbnailCategory>
                  <ThumbnailDescription>{removeMd(result.description)}</ThumbnailDescription>
                </ThumbnailDetailsContent>
              </ThumbnailDetails>
            </ThumbnailRow>
          ))}
        </MediaQuery>
      </ContentContainer>
    </ThemeProvider>
  );
};

export default Search;
