import { IconName, StickyMenuItem } from '@elearning-platform/ui';

import { Path } from '../../enums';

export const BottomNavItems: StickyMenuItem[] = [
  { icon: IconName.PageDashboard, path: Path.Dashboard },
  { icon: IconName.PageKnowledgeBase, path: Path.KnowledgeBase },
  { icon: IconName.PageTrainings, path: Path.Trainings },
  { icon: IconName.PageFormsAndTests, path: Path.FormsAndTests },
];
