import { ReactComponent as ModuleIcon } from "./images/moduleIcon.svg";

export const Config = {
  moduleName: "trainingV2",
  dependencies: [],
  label: "competence.app.trainingV2.label",
  Icon: ModuleIcon,
  urls: ["/szkolenia/:id"],
  routeName: "",
  routes: {},
  options: {},
  theme: {},
  ignoreModuleFromGlobalState: true,
};
