import React from "react";

import { ResultScreenDiv } from "./trueFalseAnswerUI";

const ResultScreen = ({ message, action, onAction }) => (
  <ResultScreenDiv>
    <h3>
      {Array.isArray(message)
        ? message.map((line, i) => [line, <br key={`br-${i}`} />])
        : message}
    </h3>
    <button className="put-in-order--button" onClick={onAction}>
      {action}
    </button>
  </ResultScreenDiv>
);

export default ResultScreen;
