import { mergeRight } from "ramda";
import memoize from "lodash.memoize";

import { AppConfig } from "../appConfig";
import { logger } from "../helpers/logger";
import * as Preloaded from "./preloaded";


const modulesList = Object.values(Preloaded.Modules).filter((module) => !module.config.ignoreModuleFromGlobalState);

export const ModuleType = Preloaded.ModuleType;

export const Widget = Preloaded.Widgets;

export const WidgetType = Preloaded.WidgetType;

export const reducers = modulesList.reduce((reducersMap, { reducer, config }) => {
  reducersMap[config.moduleName] = reducer;
  return reducersMap;
}, {});

export const sagas = modulesList.map(({ saga }) => saga);

export const getModuleComponent = (moduleName) => Preloaded.Modules[moduleName].Component;

export const bootstrap = async (selectedModules = []) => {
  await Promise.all(selectedModules.map(({ moduleName }) => getModuleComponent(moduleName)));
};

export const hasWidget = (widgetName) => !!Preloaded.Widgets[widgetName];

export const useModuleConfig = memoize((moduleName) => {
  if (!Preloaded.Modules[moduleName]) {
    logger("Module critical error", `Module ${moduleName} doesn't exist.`);
    return {};
  }
  const withDefaults = mergeRight(Preloaded.Modules[moduleName].config);
  const customConfig = AppConfig.modules.find((moduleData) => moduleData.moduleName === moduleName) || {};
  return withDefaults(customConfig);
});
