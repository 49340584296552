import { SideMenu, SideMenuItem } from '@elearning-platform/ui';
import React from 'react';

import { useMenuItems } from '../../hooks';
import LogoDark from '../../images/logoFullDark.svg';
import LogoLight from '../../images/logoFullLight.svg';

import { Container } from './sideMenuContainer.styled';

export const SideMenuContainer: React.FC = (): JSX.Element => {
  const items: SideMenuItem[] = useMenuItems();

  return (
    <Container>
      <SideMenu items={items} logo={{ dark: LogoDark, light: LogoLight }} />
    </Container>
  );
};
