import { SideMenuItem, SideMenuMobile, TopMenu, TopMenuVariant, UseState } from '@elearning-platform/ui';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavigateFunction, useNavigate } from 'react-router';
import { AnyAction, Dispatch } from 'redux';

import { Path } from '../../enums';
import { clearApiCache } from '../../helpers/clearApiCache';
import { useMenuItems } from '../../hooks';
import Logo from '../../images/logoFullLight.svg';
import { CommonActions, selectSearchPopupActive } from '../../redux';
import { selectUserAvatarSrc, selectUserName, selectUserSurname, UserContextActions } from '../../userContext';

import { Container } from './topMenuContainer.styled';
import { MenuUserData } from './topMenuContainer.types';

export const TopMenuContainer: React.FC = (): JSX.Element => {
  const dispatch: Dispatch<AnyAction> = useDispatch();
  const navigate: NavigateFunction = useNavigate();
  const userAvatar: string | null = useSelector(selectUserAvatarSrc) as string | null;
  const userName: string = useSelector(selectUserName) as string;
  const userSurname: string = useSelector(selectUserSurname) as string;
  const searchPopupActive: boolean = useSelector(selectSearchPopupActive) as boolean;
  const userData: MenuUserData = {
    username: `${userName} ${userSurname}`,
    imgSrc: userAvatar || undefined,
  };
  const items: SideMenuItem[] = useMenuItems();
  const [mobileMenuOpen, setMobileMenuOpen]: UseState<boolean> = useState<boolean>(false);

  const toggleSearchPopup: () => void = (): void => {
    dispatch(CommonActions.setSearchPopupActive(!searchPopupActive));
  };

  const onLogoutClick: () => void = (): void => {
    dispatch(UserContextActions.setLoggedOut());
    clearApiCache();
    navigate(Path.Main);
  };

  return (
    <Container>
      <TopMenu
        onBurgerMenuClick={(): void => setMobileMenuOpen(!mobileMenuOpen)}
        onLogoutClick={onLogoutClick}
        onProfileClick={(): void => navigate(Path.ProfileDetails)}
        onSearchClick={toggleSearchPopup}
        userData={userData}
        variant={TopMenuVariant.Light}
      />
      <SideMenuMobile
        items={items}
        logo={Logo}
        onClose={(): void => setMobileMenuOpen(false)}
        onLogout={onLogoutClick}
        onProfileClick={(): void => navigate(Path.ProfileDetails)}
        open={mobileMenuOpen}
        userData={userData}
      />
    </Container>
  );
};
