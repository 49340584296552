import { IconName, TextButton, TextButtonTheme } from '@elearning-platform/ui';
import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { NavigateFunction, useNavigate } from 'react-router';

import { StyledBreadcrumb } from './breadcrumb.styled';

export const Breadcrumb: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const navigate: NavigateFunction = useNavigate();

  return (
    <StyledBreadcrumb>
      {/* TODO: add correct redirection when previous view will be available */}
      <TextButton
        icon={IconName.ChevronLeft}
        label={t('competence.app.eventForm.breadcrumb.goBack')}
        onClick={(): void => navigate('/')}
        variant={TextButtonTheme.TertiaryGrey}
      />
    </StyledBreadcrumb>
  );
};
