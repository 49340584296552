import {
  Breakpoint,
  Button,
  CheckboxField,
  Color,
  Disclaimer,
  FontWeight,
  StyledComponent,
} from '@elearning-platform/ui';
import { styled } from 'styled-components';

export const Container: StyledComponent = styled.form`
  padding: 40px 16px 28px;
  width: 100%;

  @media ${Breakpoint.SmallDesktop} {
    /* Max Height = Screen height - TopBar Height and BottomBar height */
    height: calc(100vh - 84px - 80px);
    padding: 40px 16px;
    overflow-y: auto;
  }
`;

export const Wrapper: StyledComponent = styled.div`
  max-width: 496px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Fields: StyledComponent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const OptionsWrapper: StyledComponent = styled.div`
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const FieldsRow: StyledComponent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media ${Breakpoint.SmallDesktop} {
    flex-direction: row;
    gap: 8px;

    & > div {
      flex: 1;
    }
  }
`;

export const AgreementsWrapper: StyledComponent = styled.div`
  margin-top: 12px;
  margin-bottom: 28px;

  @media ${Breakpoint.SmallDesktop} {
    margin-top: 28px;
    margin-bottom: 16px;
  }
`;

export const AgreementsList: StyledComponent = styled.div`
  margin-top: 12px;
  padding-top: 12px;
  border-top: 1px solid ${Color.Gray300};
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const StyledCheckboxField: StyledComponent<typeof CheckboxField> = styled(CheckboxField)<typeof CheckboxField>`
  ol {
    list-style-type: decimal;
    margin-left: 11px;
  }

  a {
    text-decoration: underline;
  }

  input[type='checkbox'] {
    margin: 0;
  }
`;

export const StyledButton: StyledComponent<typeof Button> = styled(Button)`
  min-width: 260px;
`;

export const StyledDisclaimer: StyledComponent<typeof Disclaimer> = styled(Disclaimer)`
  gap: 0;

  & b {
    font-weight: ${FontWeight.SemiBold};
    color: ${Color.Black100};
  }
`;
