import {
  Breakpoint,
  Button,
  ButtonSize,
  ButtonTheme,
  Color,
  ShapeVariant,
} from '@elearning-platform/ui';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

import { Path } from '../../../../enums';
import { Footer } from '../footer/footer.component';
import { FooterVariant } from '../footer/footer.enums';
import { Header } from '../header/header.component';
import { HeaderVariant } from '../header/header.enums';

import {
  ButtonWrapper,
  Container,
  EmptyDiv,
  EmptyShape,
  Grid,
  HelperText,
  MainText,
  ShapeBoxes,
  Span,
  StyledShape,
  StyledShape2,
  StyledShape3,
  TextBox,
  Wrapper,
} from './actionView.styled';
import { ActionViewProps } from './actionView.types';

export const ActionView: React.FC<ActionViewProps> = ({
  primaryText,
  secondaryText,
  supportingText,
  buttonLabel,
  dark = false,
  onConfirm,
}: ActionViewProps): JSX.Element => {
  const isSmallDesktop: boolean = useMediaQuery({ query: Breakpoint.SmallDesktop });
  const isTablet: boolean = useMediaQuery({ query: Breakpoint.Tablet });

  return (
    <Container $dark={dark}>
      <Wrapper>
        <Header variant={dark ? HeaderVariant.Dark : HeaderVariant.Light} />
        <Grid>
          <EmptyShape />
          <StyledShape color={Color.Brand100} variant={ShapeVariant.Box} />
          {!!isSmallDesktop && <EmptyDiv />}
          <TextBox>
            <MainText>
              {primaryText}
              <Span>{secondaryText}</Span>
            </MainText>
            <HelperText>{supportingText}</HelperText>
          </TextBox>
          {!!isSmallDesktop && (
            <ShapeBoxes>
              <StyledShape2 color={Color.Brand100} variant={ShapeVariant.Box} />
              <StyledShape3 color={dark ? Color.Gray600 : Color.Gray300} variant={ShapeVariant.Box} />
            </ShapeBoxes>
          )}
          <ButtonWrapper>
            <Button
              internalPath={dark ? Path.Main : undefined}
              label={buttonLabel}
              onClick={dark ? undefined : onConfirm}
              size={ButtonSize.Large}
              variant={dark ? ButtonTheme.PrimaryWhite : ButtonTheme.PrimaryBrand}
            />
          </ButtonWrapper>
        </Grid>
      </Wrapper>
      {!isTablet && <Footer variant={dark ? FooterVariant.Fail : FooterVariant.Action} />}
    </Container>
  );
};
