import { createSelector, Selector } from 'reselect';

import { ThumbnailList } from '../knowledgeBaseV2.types';

import { GlobalState, State } from './knowledgeBaseV2.types';

const selectKnowledgeBaseDomain: ((data: GlobalState) => State) = (data: GlobalState): State => data.knowledgeBaseV2;

export const selectThumbnailLists: Selector = createSelector(
  selectKnowledgeBaseDomain, (data: State): ThumbnailList[] => data.lists,
);
