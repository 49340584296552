import { Breakpoint, IconName, SideMenuItem, UseRedirect, useRedirect } from '@elearning-platform/ui';
import { useMediaQuery } from '@mui/material';
import { AxiosResponse } from 'axios';
import { TransProps, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Location, useLocation } from 'react-router';
import Cookies from 'universal-cookie';

import { phpApi } from '../api';
import { NavRouteName } from '../app/mainNavigation/mainNavigation.constants';
import { AppConfig } from '../appConfig';
import { Path } from '../enums';
import { AvailableRoutes, ModuleConfig } from '../interfaces';
import { selectAvailableRoutes } from '../userContext';

export const useMenuItems: () => SideMenuItem[] = (): SideMenuItem[] => {
  const { pathname }: Location = useLocation();
  const { t }: TransProps<never> = useTranslation();
  const { redirect }: UseRedirect = useRedirect();
  const isSmallDesktop: boolean = useMediaQuery(Breakpoint.SmallDesktop);
  const availableRoutes: AvailableRoutes | undefined = useSelector(selectAvailableRoutes) as AvailableRoutes | undefined;
  const availableItems: ModuleConfig[] = AppConfig.modules.filter(
    (item: ModuleConfig): boolean => !!availableRoutes && availableRoutes[item.routeName]
  ) as ModuleConfig[];

  const items: SideMenuItem[] = availableItems.map(({ urls, label, modernIconName }: ModuleConfig): SideMenuItem => ({
    label: t(label),
    internalPath: urls[0],
    icon: modernIconName || IconName.PageDocuments,
    current: urls.some(
      (url: string): boolean => url === pathname || (url !== Path.Main && pathname.includes(url))
    ),
  }));

  const handleRedirect: () => void = (): void => {
    void phpApi.post('/api/user/management', {}).then(({ data }: AxiosResponse): void => {
      const cookies: Cookies = new Cookies();

      Object.keys(data.cookies).forEach((element: string): void => {
        cookies.set(element, data.cookies[element], { path: Path.Main });
      });

      redirect(`${AppConfig.api}${data.redirect}`, { isExternal: true, inNewTab: true });
    });
  };

  const adminItem: SideMenuItem | undefined = !!availableRoutes && availableRoutes[NavRouteName.Management] && isSmallDesktop
    ? {
        label: t('app.mainNavigation.management'),
        icon: IconName.PageManagement,
        action: handleRedirect,
      }
    : undefined;

  return adminItem ? [...items, adminItem] : items;
};
