import { Breakpoint, Color, StyledComponent } from '@elearning-platform/ui';
import { styled } from 'styled-components';

export const Wrapper: StyledComponent = styled.div`
  width: 100%;
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
  align-items: center;
  background-color: ${Color.Gray100};
  padding: 0;

  @media ${Breakpoint.DesktopXL} {
    justify-content: center;
    align-items: flex-start;
    padding: 0 100px;
  }
`;

export const Container: StyledComponent = styled.div`
  width: 100%;
  max-width: 1281px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  @media ${Breakpoint.Desktop} {
    border-left: 1px solid ${Color.Gray300};
  }
`;
